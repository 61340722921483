import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import FreelancingImg from '../assets/images/freelancing-course.png';
import GraphicsImg from '../assets/images/graphic-designing-course.png';
import WebDesignImg from '../assets/images/web-design-course.png';
import UIImg from '../assets/images/ui-course.png';
import DigitalMarketingImg from '../assets/images/digital-marketing-course.png';
import VAImg from '../assets/images/va-course.png';
import EcommerceImg from '../assets/images/ecommerce-course.png';
import EditingImg from '../assets/images/editing-course.png';

const CoursesOffered = () => {

    const coursesData = [
        { title: 'Freelancing', img: FreelancingImg },
        { title: 'Graphics Design', img: GraphicsImg },
        { title: 'Web Design', img: WebDesignImg },
        { title: 'UI/UX', img: UIImg },
        { title: 'Digital Marketing', img: DigitalMarketingImg },
        { title: 'Virtual Assistant', img: VAImg },
        { title: 'E Commerce', img: EcommerceImg },
        { title: 'Video & Photo Editing', img: EditingImg },
    ];

    return (
        <Container className="services-container my-5">
            <h5>Courses Offered</h5>
            <p className='text-center my-3'>
                Offering Courses the excitement they want in their games. Leverage NFT Game Development for credible and secure game assets.
            </p>
            <Row className="services-row">
                {coursesData?.map((data, index) => (
                    <Col
                        xs="12"
                        md="6"
                        lg="3"
                        key={index}
                        data-aos="fade-up"
                        data-aos-anchor-placement="top-center"
                    >
                        <div className="services-card text-center">
                            <Image src={data?.img} alt="services=img" />
                            <h5>{data?.title}</h5>
                        </div>
                    </Col>
                ))}
            </Row>
        </Container>
    )
}

export default CoursesOffered;
