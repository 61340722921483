import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import "../assets/css/Services.css";
import { servicesData } from "../Data";

const Services = () => {
  const navigate = useNavigate();
  return (
    <Container className="services-container">
      <h3>OUR SERVICES</h3>
      <Row className="services-row">
        {servicesData?.map((data, index) => (
          <Col
            xs="12"
            md="6"
            lg="4"
            key={index}
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
          >
            <div className="services-card">
              <Image src={data?.img} alt="services=img" />
              <h5>{data?.heading}</h5>
              <p>{data?.text}</p>
              <button onClick={() => navigate(`/services/${data?.id}`)}>
                Read More
                <span className="ml-2">
                  <BsArrowRight />
                </span>
              </button>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Services;
