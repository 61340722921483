import React from "react";
import "../assets/css/GradientHeader.css";

const GradientHeader = ({ heading, text, activeText }) => {
  return (
    <div className="gradient-component">
      <h3>{heading}</h3>
      {activeText && <div aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <p>Home</p>
          </li>
          {text && <li className="breadcrumb-item">{text}</li>}
          {activeText && <li className="breadcrumb-item active" aria-current="page">
            {activeText}
          </li>}
        </ol>
      </div>}
    </div>
  );
};

export default GradientHeader;
