import React from "react";
import { Container, Row, Col, Image, Accordion } from "react-bootstrap";
import CustomButton from "../shared/CustomButton";
import "../assets/css/ArtworkDetail.css";
import PageHeader from "../shared/CustomHeader";
import { artServicesData, artServicesTextData } from "../Data";
import { useNavigate } from "react-router-dom";
import MissionImg from "../assets/images/faqs.png";

const ArtHero = () => {
  const navigate = useNavigate();
  return (
    <>
      {/*Discord Details Hero */}
      <div className="art-details-container">
        <Container className="home-container ">
          <Row className="home-row  services-hero-row">
            <Col
              xs="12"
              sm="12"
              md="8"
              lg="6"
              data-aos="zoom-in"
              data-aos-easing="linear"
              data-aos-duration="300"
            >
              <div className="home-left-column">
                <h6>ARTWORK</h6>
                <h1>
                  Nurture Your Nft Project! Generate Exclusively Crafted Nft Art
                </h1>
                <p>
                  We create NFT art designs that go beyond the imagination from
                  anime characters, avatars, theme-based NFTs or assets, digital
                  weapons, comic characters, or anything you can dream off.
                </p>
                <CustomButton
                  variant="success"
                  btnClick={() => navigate("/contact-us")}
                >
                  GET QUOTATION{" "}
                </CustomButton>
              </div>
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="6"
              data-aos="zoom-in-up"
              data-aos-anchor-placement="top-bottom"
            ></Col>
          </Row>
        </Container>
      </div>
      {/* art Details Second Section */}
      <div className="art-card-container" data-aos="fade-up">
        <Container>
          <h3 className="text-center">
            Why choose us to help you design your NFT artwork?
          </h3>
          <Row className="art-row">
            <Col sm="12" md="6" lg="3" data-aos="zoom-in-up">
              <div className="art-services-text-card">
                <h5>Asset & NFT Design Agency</h5>
                <p>
                  The NFT space is forever changing how digital media is traded
                  and owned. Our Artists and creators can help to take your
                  creations into the metaverse.
                </p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="3" data-aos="zoom-in-up">
              <div className="art-services-text-card">
                <h5>Highly Experienced Team</h5>
                <p>
                  Our Highly experienced team of artist to ensure that the NFT
                  artwork professionally created with attention to detail and
                  unlimited revisions is highly unique and profitable for the
                  creators to gain their commission forever and anon.
                </p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="3" data-aos="zoom-in-up">
              <div className="art-services-text-card">
                <h5>Round the Clock Support</h5>
                <p>
                  We offer our clients 24x7 round the clock support on any
                  queries regarding the project. All questions will be resolved
                  and replied to in a timely manner.
                </p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="3" data-aos="zoom-in-up">
              <div className="art-services-text-card">
                <h5>On-Time Delivery</h5>
                <p>
                  We follow an agile methodology when it comes to serving our
                  clients. We offer them the best services on time.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* art Details Third Section */}
      <div className="art-desc-container">
        <Container>
          <Row className="d-flex justify-content-center">
            {artServicesTextData?.map((data, index) => (
              <Col
                sx="12"
                sm="12"
                md="10"
                lg="8"
                key={index}
                data-aos="fade-down-right"
              >
                <div className="art-desc-card">
                  <h5>{data?.heading}</h5>
                  <p>{data?.desc1}</p>
                  <p>{data?.desc2}</p>
                  <p>{data?.desc3}</p>
                  <p>{data?.desc4}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <Container className="faqs-container" style={{ paddingTop: "50px" }}>
        <Row>
          <Col
            sx="12"
            md="12"
            lg="6"
            data-aos="zoom-in-up"
            data-aos-anchor-placement="top-bottom"
          >
            <Image
              src={MissionImg}
              lazyloading="true"
              alt="faq"
              rounded={true}
              className="faq-img"
            />
          </Col>
          <Col
            sx="12"
            md="12"
            lg="6"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <div className="faqs-column-right">
              <PageHeader
                text="Our Faqs"
                heading="Frequently Asked Questions."
              />
              <div className="faq-row">
                {artServicesData?.map((data, index) => (
                  <Accordion flush key={index}>
                    <Accordion.Item eventKey={data?.id.toString()}>
                      <Accordion.Header>{data?.text}</Accordion.Header>
                      <Accordion.Body>{data?.desc}</Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ArtHero;
