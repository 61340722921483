import React from "react";
import GradientHeader from "../shared/GradientHeader";
import DiscordHero from "./../components/DiscordHero";

const DiscordDetails = () => {
  return (
    <div>
      <GradientHeader
        heading={"Discord Marketing"}
        text={"Services"}
        activeText={"Discord"}
      />
      <DiscordHero />
    </div>
  );
};

export default DiscordDetails;
