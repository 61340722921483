import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import GameLogo from "../assets/images/gaming.png";
import "../assets/css/GamingDetails.css";

const GameCards = () => {
  return (
    <div className="game-service-container" data-aos="fade-up">
      <Container>
        <h3 className="game-heading">Our NFT Game Development Services</h3>

        <p className="game-heading-text">
          Craft games that generate lasting value for players. Offer a
          tier-access user experience by developing NFT games engineered built
          by an expert NFT game development company.
        </p>

        <Row className="mt-5">
          <Col sm="12" md="6 " lg="6">
            <div className="services-game-card">
              <div className="d-flex align-items-center justify-content-between my-2">
                <h5>
                  NFT Game Development <br /> Services
                </h5>
                <Image src={GameLogo} alt="game-logo" />
              </div>
              <p>
                Let a classy development team that latches onto business
                opportunities help you build NFT games for any genre you wish to
                dominate.
              </p>
            </div>
          </Col>
          <Col sm="12" md="6 " lg="6">
            <div className="services-game-card">
              <div className="d-flex align-items-center justify-content-between my-2">
                <h5>
                  Customizable NFT <br /> Games
                </h5>
                <Image src={GameLogo} alt="game-logo" />
              </div>
              <p>
                You can build customizable NFT games with your unique digital
                assets identified through blockchain-based gaming platforms.
              </p>
            </div>
          </Col>
          <Col sm="12" md="6 " lg="6">
            <div className="services-game-card">
              <div className="d-flex align-items-center justify-content-between my-2">
                <h5>
                  NFT Game <br /> Conceptualization
                </h5>
                <Image src={GameLogo} alt="game-logo" />
              </div>
              <p>
                Brainstorm and ideate to formulate and refine game concepts with
                a promising future in minefield of competition with massive
                earning opportunities.
              </p>
            </div>
          </Col>

          <Col sm="12" md="6 " lg="6">
            <div className="services-game-card">
              <div className="d-flex align-items-center justify-content-between  my-2">
                <h5>
                  NFT Game Development <br /> For Android And IOS
                </h5>
                <Image src={GameLogo} alt="game-logo" />
              </div>
              <p>
                Build an NFT game that offers the best of both worlds with an
                experienced team that knows just what to include and exclude for
                a rich and stimulating experience.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GameCards;
