import React from "react";
import "../assets/css/GamingDetails.css";
import { Col, Container, Row } from "react-bootstrap";

const GameCardSection = () => {
  return (
    <div className="game-card-container" data-aos="fade-up">
      <Container>
        <h3>Release Exciting NFT Games</h3>

        <p>
          Craft games that generate lasting value for players. Offer a
          tier-access user experience by developing NFT games engineered built
          by an expert NFT game development company.
        </p>

        <Row className="game-row">
          <Col sm="12" md="6" lg="3" data-aos="zoom-in-up">
            <div className="game-text-card">
              <h5> Digital Agreements</h5>
              <p>
                Build your NFT products to protect your marketplace token
                transactions.
              </p>
            </div>
          </Col>
          <Col sm="12" md="6" lg="3" data-aos="zoom-in-up">
            <div className="game-text-card">
              <h5> Decentralized Platform</h5>
              <p>
                Update your NFT data on a blockchain no matter when a new block
                is introduced.
              </p>
            </div>
          </Col>
          <Col sm="12" md="6" lg="3" data-aos="zoom-in-up">
            <div className="game-text-card">
              <h5>Instant Payments</h5>
              <p>
                No need for card details or other personal information for
                enabling trade.
              </p>
            </div>
          </Col>
          <Col sm="12" md="6" lg="3" data-aos="zoom-in-up">
            <div className="game-text-card">
              <h5> Algorithmic Trade</h5>
              <p>
                Reach buyers and sellers automatically in line with their
                searching experience.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GameCardSection;
