import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomButton from "../shared/CustomButton";
import "../assets/css/GamingDetails.css";
import { useNavigate } from "react-router-dom";
import GameCardSection from "./GameCardSection";
import GameCards from "./GameCards";
import GameServicesDescription from "./GameServicesDescription";
import GameServicesEnd from "./GameServicesEnd";

const GamingHero = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className=" gaming-banner">
        <Container className="home-container ">
          <Row className="home-row  services-hero-row">
            <Col
              xs="12"
              sm="12"
              md="8"
              lg="6"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <div className="home-left-column services-left-column">
                <h6>NFT GAME DEVELOPMENT</h6>
                <h1>Create Games With Uniques Digital Assets - Nft </h1>
                <p>
                  Offering game lovers the excitement they want in their
                  games.Leverage NFT Game Development for credible and secure
                  game assets.
                </p>
                <CustomButton
                  variant="success"
                  btnClick={() => navigate("/contact-us")}
                  style={{ width: "320px" }}
                >
                  Connect With Our Experts{" "}
                </CustomButton>
              </div>
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="6"
              data-aos="zoom-in-up"
              data-aos-anchor-placement="top-bottom"
            ></Col>
          </Row>
        </Container>
      </div>
      <GameCardSection />
      <GameCards />
      <GameServicesDescription />
      <GameServicesEnd />
    </>
  );
};

export default GamingHero;
