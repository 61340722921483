import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageHeader from "../shared/CustomHeader";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../assets/css/Reviews.css";
import { reviewsData } from "../Data";

const Reviews = () => {
  return (
    <Container className="reviews-container">
      <Row>
        <Col xs="12" md="12" lg="4" className="reviews-column-left">
          <PageHeader
            text={"REVIEWS"}
            heading={"Some Of Our Clients."}
          />
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
            tellus, luctus nec ullamcorper mattis.
          </p> */}
        </Col>
        <Col
          xs="12"
          md="12"
          lg="8"
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
        >
          <div className="reviews-column-right">
            <Carousel
              additionalTransfrom={0}
              arrows={false}
              showDots={false}
              autoPlay={true}
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={true}
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 1,
                  partialVisibilityGutter: 60,
                },
                mobile: {
                  breakpoint: {
                    max: 600,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 601,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
              }}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {reviewsData?.map((data, index) => (
                <div className="reviews-card" key={index}>
                  <p>{data?.text}</p>
                  <div className="d-flex mt-5">
                    <span className="icon-quote">{data?.icon}</span>
                    <div className="reviews-card-inner">
                      <h5>{data?.name}</h5>
                      <h6>{data?.designation}</h6>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Reviews;
