import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../assets/css/Navbar.css";
import Logo from "../assets/images/logo.png";
import { AiOutlineBars, AiOutlineClose } from "react-icons/ai";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const closeMenu = () => setMenuOpen(false);
  const [navbarActive, setNavbarActive] = useState(false);
  //Handle Navbar Toggle
  const handleToggle = () => {
    setMenuOpen(!menuOpen);
  };
  //Change Navbar Color
  const changeNavColor = () => {
    if (window.scrollY >= 100) {
      setNavbarActive(true);
    } else {
      setNavbarActive(false);
    }
  };
  window.addEventListener("scroll", changeNavColor);

  return (
    <>
      <nav
        className={`navbar  navbar-light ${
          navbarActive ? "navbar nav-active" : "navbar-simple"
        }`}
      >
        <div className="container">
          <Link to="/" className="navbar-logo">
            <img src={Logo} alt="Navbar Logo" style={{width:'100px'}} />
          </Link>
          <div className="input-container d-flex align-center">
            <input
              className=" mr-sm-2"
              type="search"
              placeholder="Search Products"
              aria-label="Search"
            />

            <p className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#home"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
              >
                Search Category
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a className="dropdown-item" href="#home">
                  Action
                </a>
                <a className="dropdown-item" href="#home">
                  Another action
                </a>
                <a className="dropdown-item" href="#home">
                  Something else here
                </a>
              </div>
            </p>
           
          </div>
        
        </div>
        <div className="nav-toggle-icon">
          {!menuOpen ? (
            <AiOutlineBars size={30} onClick={handleToggle} />
          ) : (
            <AiOutlineClose size={30} onClick={handleToggle} />
          )}
        </div>

        <div className={`nav-toggle-items ${menuOpen ? "menu-active" : ""}`}>
          <div className="d-flex">
            <input
              className=" mr-sm-2"
              type="search"
              placeholder="Search For Products"
              aria-label="Search"
            />
           
          </div>
          <ul>
            <li onClick={closeMenu}>Birthday</li>
            <hr />
            <li onClick={closeMenu}>Valentines</li>
            <hr />
            <li onClick={closeMenu}>Christmas</li>
            <hr />
            <li onClick={closeMenu}>LockDown Card</li>
            <hr />
            <li>WishList</li>
            <hr />
            <li onClick={closeMenu}>
              <Link to="/login">Login/Register</Link>
            </li>
            <hr />
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
