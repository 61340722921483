import React, { useRef, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import emailjs from "@emailjs/browser";
import "../assets/css/ContactForm.css";
import CustomHeader from "./../shared/CustomHeader";
import TextField from "./../shared/TextField";
import ContactImg from "../assets/images/contact.jpg";

const ContactForm = () => {
  const formRef = useRef("");
  const [success, setSuccess] = useState(false);
  const initialValues = {
    name: "",
    email: "",
    message: "",
  };
  const errorSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("Email is required"),
    message: Yup.string().required("Message  is required"),
  });
  const handleSubmit = (values, { resetForm }) => {
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        formRef.current,
        process.env.REACT_APP_EMAIL_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.status);
          resetForm();
          setSuccess(true);
        },
        (error) => {
          console.log(error);
          console.log(error.text);
        }
      );
    setTimeout(() => {
      setSuccess(false);
    }, 4000);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={errorSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Container className="contact-container">
          <Row>
            <Col xs="12" md="12" lg="6" data-aos="zoom-in-up">
              <Image
                src={ContactImg}
                className="contact-img"
                lazyloading="true"
                alt="contact-img"
              />
            </Col>
            <Col xs="12" md="12" lg="6">
              <div className="contact-right-column">
                <CustomHeader
                  heading={"Drop Up A Message."}
                  text={"CONTACT US"}
                />
                <Row>
                  <Form ref={formRef}>
                    <Col xs="12" md="12" lg="12" className="px-0">
                      <TextField
                        label="Name"
                        type="text"
                        name="name"
                        placeholder="Enter Your Name"
                      />
                    </Col>
                    <Col xs="12" md="12" lg="12" className="px-0">
                      <TextField
                        label="Email"
                        type="email"
                        name="email"
                        placeholder="Enter Your Email"
                      />
                    </Col>
                    <Col xs="12" md="12" lg="12" className="px-0">
                      <TextField
                        label="Message"
                        type="textarea"
                        textarea="true"
                        name="message"
                        as="textarea"
                        rows="6"
                        placeholder="Your Message"
                      />
                    </Col>
                    <button className="contact-btn">Submit</button>
                  </Form>
                  {success ? (
                    <span className="text-success mt-3">
                      Thank You For Response Our Team Will Contact You ASAP!
                    </span>
                  ) : null}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </Formik>
  );
};

export default ContactForm;
