import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import CustomButton from "../shared/CustomButton";
import "../assets/css/DiscordDetails.css";
import DiscordLogo from "../assets/images/marketing.png";
import Discord from "../assets/images/discord.png";
import Discord1 from "../assets/images/discord1.png";
import { discordDetailsData } from "../Data";
import { useNavigate } from "react-router-dom";

const DiscordHero = () => {
  const navigate = useNavigate();
  return (
    <>
      {/*Discord Details Hero */}
      <div className="discord-details-section-1">
        <Container className="home-container ">
          <Row className="home-row  services-hero-row">
            <Col
              xs="12"
              sm="12"
              md="8"
              lg="6"
              data-aos="fade-down-right"
              data-aos-anchor-placement="top-bottom"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <div className="home-left-column">
                <h6>DISCORD MARKETING</h6>
                <h1>Competent Discord Marketing Services For Nft Projects </h1>
                <p>
                  Broaden your reach within the NFT community by establishing a
                  solid presence in Discord, the social hub for NFT enthusiasts.
                </p>
                <CustomButton
                  variant="success"
                  btnClick={() => navigate("/contact-us")}
                >
                  GET QUOTATION{" "}
                </CustomButton>
              </div>
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="6"
              data-aos="zoom-in-up"
              data-aos-anchor-placement="top-bottom"
            ></Col>
          </Row>
        </Container>
      </div>
      {/* Discord Details Second Section */}
      <div className="discord-detail-second-container">
        <Container
          className="discord-detail-second-inner-container"
          data-aos="zoom-in-up"
        >
          <h3>Elevate Your Business With Effective Discord Marketing</h3>
          <p>
            Discord is a VoIP chat platform that allows gamers, influencers, NFT
            projects, cryptocurrency enthusiasts, brands, and anyone to build a
            community and interact with its members. It is not a very popular
            platform compared to giants like Facebook, Instagram or Twitter, but
            it has its own characteristics that make it the platform of choice
            for brands that want to build large and engaging communities. With a
            comprehensive marketing campaign, Discord gives your business a wide
            reach. This includes adding roles to community members, creating
            different discussion groups, and more. With features like helping
            community owners moderate their membership, you can create a
            friendly ecosystem for everyone. Learn how to take your business to
            the next level with this amazing platform.
          </p>
          <p>
            Aethon International creates customized marketing strategies that
            best suit your niche. We run holistic campaigns that cover every
            corner of the sales funnel, including brand awareness, lead
            generation, conversions, user acquisition, sales and retention. It
            offers interesting copy, engaging photos and videos, announcements,
            interactive messages and more. We can help you in writing. Most
            recently, most NFT projects have found a home on Discord for their
            exemplary community building features. If you're a business looking
            to build and engage with a large community, contact us today to
            start a Discord marketing campaign.
          </p>
          <button onClick={() => navigate("/contact-us")}>Buy Now</button>
        </Container>
      </div>
      {/* Discord Details Third Section */}
      <div className="discord-services-container">
        <Container className="discord-details-third-container">
          <h3>
            Perks Of Employing Our Discord Marketing Channel To Your Crypto
            Project
          </h3>
          <Row className="discord-row">
            <Col sm="12" md="6 " lg="6" data-aos="zoom-out-up">
              <div className="discord-card">
                <div className="d-flex align-items-center justify-content-between my-2">
                  <h5>Community Building</h5>
                  <Image src={DiscordLogo} alt="game-logo" />
                </div>
                <p>
                  A Discord Marketing Company like us helps you in building a
                  huge and loyal community for your business in Discord. This
                  can be achieved by hosting targeted social discussions
                  bringing together like-minded people.
                </p>
              </div>
            </Col>
            <Col sm="12" md="6 " lg="6" data-aos="zoom-out-up">
              <div className="discord-card">
                <div className="d-flex align-items-center justify-content-between my-2">
                  <h5>Quick Customer Services</h5>
                  <Image src={DiscordLogo} alt="game-logo" />
                </div>
                <p>
                  Consumers today don’t prefer long waits to get their queries
                  resolved. We will help you create a dedicated server to handle
                  customer requests where you can provide answers to all their
                  queries instantly.
                </p>
              </div>
            </Col>
            <Col sm="12" md="6 " lg="6" data-aos="zoom-out-up">
              <div className="discord-card">
                <div className="d-flex align-items-center justify-content-between my-2">
                  <h5>Networking</h5>
                  <Image src={DiscordLogo} alt="game-logo" />
                </div>
                <p>
                  In addition to building a loyal community, effective discord
                  marketing strategies can enable you to network with similar
                  businesses on partnerships, collaborations, and more.
                </p>
              </div>
            </Col>

            <Col sm="12" md="6 " lg="6" data-aos="zoom-out-up">
              <div className="discord-card">
                <div className="d-flex align-items-center justify-content-between  my-2">
                  <h5>Branding</h5>
                  <Image src={DiscordLogo} alt="game-logo" />
                </div>
                <p>
                  We will help you share the story of your brand straight to the
                  clients with attractive images and videos on your Discord
                  channel.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Services Section 4 */}
      <div className="services-section-4-container">
        <Container>
          <Row>
            <Col
              xs="12"
              md="12"
              lg="6"
              className="services-section-4-left-column"
              data-aos="zoom-in"
            >
              <h3>Why Are Brands Opting For Discord Promotion Services?</h3>
              <p>
                Recently, many brands, games, NFT projects and crypto projects
                are paying more attention to Discord marketing. Discord has no
                built-in features to help you promote your product, but it is
                widely chosen by NFT players and enthusiasts for its sole
                purpose of building a community. This allows brands to connect
                with their customers and explore their expectations and needs as
                they successfully build healthy communities and create different
                channels to express their vision for a crypto project or game.
                The better you know your customers, the better you can plan. So
                contact us to build a great community and grow your business on
                Discord.
              </p>
            </Col>
            <Col xs="12" md="12" lg="6">
              <Image
                src={Discord}
                alt="discord-img"
                className="discord-img"
                data-aos="zoom-in"
              />
            </Col>
          </Row>
        </Container>
      </div>
      {/* Services Section 5 */}
      <div className="discord-details-section-5">
        <Container>
          <h3>A Quick Glance At Our Discord Promotion Services</h3>
          <Row className="discord-row">
            {discordDetailsData?.map((data, index) => (
              <Col xs="12" md="6" lg="4" key={index}>
                <div className="discord-section-5-card" data-aos="">
                  <Image src={data?.logo} />
                  <h5>{data?.heading}</h5>
                  <hr />
                  <p>{data?.text}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      {/* Services Section 6*/}
      <div className="services-section-4-container">
        <Container>
          <Row>
            <Col xs="12" md="12" lg="6" data-aos="zoom-in">
              <Image src={Discord1} alt="discord-img" className="discord-img" />
            </Col>
            <Col
              xs="12"
              md="12"
              lg="6"
              className="services-section-4-left-column"
              data-aos="zoom-in"
            >
              <h3>Why Choose Us For Discord Marketing Services?</h3>
              <p>
                There are several reasons to choose us as your Discord marketing
                agency. Highlights include our experience, expertise,
                results-oriented strategy and industry expertise. If you are
                looking for something other than the list above, we are a team
                that listens to you, understands your needs and niche, tailors
                marketing campaigns to suit your needs and delivers faster
                results, both quantitatively and qualitatively. Among other
                things, we're not just a Discord marketing company, we also
                offer social media promotions, PR, Telegram marketing and more.
                We can help with other marketing campaigns such as: Simply put,
                it's a 360-degree solution. Contact us today to start developing
                a successful strategy for your business.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DiscordHero;
