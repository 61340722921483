import React from "react";
import { Container, Image } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { clientsSliderData } from "../Data";

const ClientSlider = () => {
  return (
    <Container className="mt-4">
      <Carousel
        additionalTransfrom={0}
        arrows={false}
        showDots={false}
        autoPlay={true}
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={true}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 4,
            partialVisibilityGutter: 60,
          },
          mobile: {
            breakpoint: {
              max: 600,
              min: 0,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 601,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {clientsSliderData?.map((data, index) => (
          <div className="mx-auto client-image" key={index}>
            <Image
              src={data.img}
              alt="choose-card-item"
              style={{ width: "160px" }}
            />
          </div>
        ))}
      </Carousel>
    </Container>
  );
};

export default ClientSlider;
