import React from "react";
import Metaverse from "../components/Metaverse";
import GradientHeader from "./../shared/GradientHeader";

const MetaverseDetails = () => {
  return (
    <div>
      <GradientHeader
        heading={"Metaverse"}
        text={"Services"}
        activeText={"Metaverse"}
      />
      <Metaverse />
    </div>
  );
};

export default MetaverseDetails;
