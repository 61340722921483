import React from "react";
import { Container, Row, Col, Image, Accordion } from "react-bootstrap";
import CustomButton from "../shared/CustomButton";
import "../assets/css/ContractDetails.css";
import PageHeader from "../shared/CustomHeader";
import { smartContractFaqs } from "../Data";
import { useNavigate } from "react-router-dom";
import { smartContractsData } from "./../Data";
import MissionImg from "../assets/images/faqs.png";

const SmartContractHero = () => {
  const navigate = useNavigate();
  return (
    <>
      {/*Discord Details Hero */}
      <div className="contract-details-container">
        <Container className="home-container ">
          <Row className="home-row  services-hero-row">
            <Col
              xs="12"
              sm="12"
              md="8"
              lg="6"
              data-aos="fade-down-right"
              data-aos-anchor-placement="top-bottom"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <div className="home-left-column">
                <h6>SMART CONTRACTS</h6>
                <h1>
                  Robust Nft Smart Contract Development From Industry Adepts{" "}
                </h1>
                <p>
                  Get well-engineered NFT smart contract development services
                  from our expert blockchain development professionals. Our
                  developers possess the ability to develop smart contracts on
                  multiple blockchain networks like Ethereum, TRON, Hyperledger
                  etc.
                </p>
                <CustomButton
                  variant="success"
                  btnClick={() => navigate("/contact-us")}
                  style={{ width: "320px" }}
                >
                  Connect With Our Experts{" "}
                </CustomButton>
              </div>
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="6"
              data-aos="zoom-in-up"
              data-aos-anchor-placement="top-bottom"
            ></Col>
          </Row>
        </Container>
      </div>
      {/* art Details Second Section */}
      <div className="contract-card-container" data-aos="fade-up">
        <Container>
          <h3 className="text-center">
            Smart Contract-Based NFT Development Process We Follow
          </h3>
          <Row className="mt-5">
            <Col sm="12" md="6" lg="4">
              <div className="contract-text-card">
                <h5>
                  Stage 1: <br />
                  Formulate Requirements
                </h5>
                <p>
                  In the first place, our analyst team will sit with you to
                  analyze your NFT project requirements. Here, you can specify
                  the blockchain tech to develop the smart contracts for your
                  NFT marketplace.
                </p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="contract-text-card">
                <h5>
                  Step 2: <br /> Coding Smart Contracts
                </h5>
                <p>
                  We will code the smart contracts using a certain programming
                  language, which will be based on the blockchain you prefer.
                  Coding the contracts will involve implementing the required
                  functionality of the marketplace.
                </p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4">
              <div className="contract-text-card">
                <h5>
                  Step 3: <br />
                  Testing & Deploying
                </h5>
                <p>
                  After developing the smart contracts, testers will test them
                  on the testnet and then on the mainnet. The testing team will
                  carry out smart contract auditing to ensure that they are
                  bug-free. Finally, they are deployed on the mainnet.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* art Details Third Section */}
      <div className="faqs-desc-container">
        <Container>
          <Row className="d-flex justify-content-center">
            {smartContractsData?.map((data, index) => (
              <Col sx="12" sm="12" md="10" lg="8" key={index}>
                <div className="faqs-desc-card">
                  <h5>{data?.heading}</h5>
                  <p>{data?.desc1}</p>
                  <p>{data?.desc2}</p>
                  <p>{data?.desc3}</p>
                  <p>{data?.desc4}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <Container className="faqs-container faqs-contract-container">
        <Row>
          <Col sx="12" md="12" lg="6" data-aos="zoom-in-up">
            <Image
              src={MissionImg}
              lazyloading="true"
              alt="faq"
              rounded={true}
              className="faq-img"
            />
          </Col>
          <Col sx="12" md="12" lg="6">
            <div className="faqs-column-right">
              <PageHeader
                text="Our Faqs"
                heading="Frequently Asked Questions."
              />
              <div className="faq-row">
                {smartContractFaqs?.map((data, index) => (
                  <Accordion flush key={index}>
                    <Accordion.Item eventKey={data?.id.toString()}>
                      <Accordion.Header>{data?.text}</Accordion.Header>
                      <Accordion.Body>{data?.desc}</Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SmartContractHero;
