import React from "react";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import CustomButton from "./../shared/CustomButton";
import { useNavigate } from "react-router-dom";
import "../assets/css/AppDetails.css";

const AppHero = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="app-details-container mobile-bg">
        <Container className="home-container ">
          <Row className="home-row  services-hero-row">
            <Col
              xs="12"
              sm="12"
              md="8"
              lg="6"
              data-aos="fade-down-right"
              data-aos-anchor-placement="top-bottom"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <div className="home-left-column">
                <h6>APP</h6>
                <h1>Mobile App Development </h1>
                <p>
                  We build delightful digital experiences At Aethon we combine
                  creativity, technical expertise, and process-driven
                  development to create innovative products for our clients.
                </p>
                <CustomButton
                  variant="success"
                  btnClick={() => navigate("/contact-us")}
                  style={{ width: "320px" }}
                >
                  Connect With Our Experts{" "}
                </CustomButton>
              </div>
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="6"
              data-aos="zoom-in-up"
              data-aos-anchor-placement="top-bottom"
            ></Col>
          </Row>
        </Container>
      </div>
      <div className="app-card-container" data-aos="fade-up">
        <Container>
          {/* <h3 className="text-center">Designing Process We Follow</h3> */}
          <Row>
            <Col sm="12" md="6" lg="6" data-aos="zoom-in-up">
              <div className="app-text-card">
                <h5>
                  We're a company ready to tackle your biggest challenges.
                </h5>
                <p>
                  With decades of enterprise-level software engineering
                  experience and deep product development expertise, our
                  UK-based, professional team delivers high-quality code. We
                  offer clients a dedicated software product development team
                  for a Quality Software. And we never sacrifice quality for
                  speed. We offer a Bug-Free Warranty and take pride in our
                  independent, honest judgment, which allows us to recommend the
                  best, most cost-effective technology for your particular
                  project.
                </p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="6" data-aos="zoom-in-up">
              <div className="app-text-card">
                <h5>You're the dreamers. We're the doers.</h5>
                <p>
                  While other custom software development companies might be
                  unwilling to tackle multiple layers of complexity in the
                  projects they take on, we're different. No software challenge
                  is too daunting for us; no spec too complex. Our passion is
                  bringing your passion to life. We do that through expert
                  software development and development process consulting
                  services. That's what makes us one of the best software
                  companies in UK.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="app-services-card-container">
        <h3 className="text-center">Full Cycle Of Development Services</h3>
        <Container data-aos="zoom-in-up">
          <Row className="app-row">
            <Col xs="12" md="6" lg="4">
              <div className="app-services-card">
                <h5>IOS Development</h5>
                <p>
                  We leverage the strength of the iOS platform to help our
                  clients solve their most complicated business challenges.
                  During years, our dedicated iOS developers have delivered apps
                  to over 100 customers.
                </p>
              </div>
            </Col>
            <Col xs="12" md="6" lg="4">
              <div className="app-services-card">
                <h5>Android Development</h5>
                <p>
                  Our mobile app developers know how to create top-notch user
                  experiences on Android. We design applications with attention
                  to detail, and have deep expertise in every aspect of Android
                  app development, resulting in extraordinary digital
                  experiences.
                </p>
              </div>
            </Col>
            <Col xs="12" md="6" lg="4">
              <div className="app-services-card">
                <h5>Web App Development</h5>
                <p>
                  We build modern web apps to help our clients solve their most
                  complicated business challenges. Our solutions are productive,
                  responsive, easy to maintain, and implement flawlessly across
                  devices, browsers, and operating systems – your app can be
                  everywhere your users are.
                </p>
              </div>
            </Col>
            <Col xs="12" md="6" lg="4">
              <div className="app-services-card">
                <h5>Quality Assurance</h5>
                <p>
                  Our expert quality assurance team tests and iterates on
                  functionalities to make sure the entire product works
                  flawlessly and users get exactly what they love.
                </p>
              </div>
            </Col>
            <Col xs="12" md="6" lg="4">
              <div className="app-services-card">
                <h5>Project Management</h5>
                <p>
                  Our Project Managers follow the best Agile practices
                  delivering the projects. Such an approach helps us to adapt to
                  changes quicker yet maintain the quality of the products we
                  develop at its highest.
                </p>
              </div>
            </Col>
            <Col xs="12" md="6" lg="4">
              <div className="app-services-card">
                <h5>Tech Consulting</h5>
                <p>
                  If you have an app idea but don’t know what to start with, we
                  have a good first step to take! We have business analysts who
                  can create a business strategy for you.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AppHero;
