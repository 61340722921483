import React from "react";
import ClientSlider from "../components/ClientSlider";
import Faqs from "../components/Faqs";
import HomeContainer from "../components/HomeContainer";
import OurMission from "../components/OurMission";
import Portfolio from "../components/Portfolio";
import Reviews from "../components/Reviews";
import Services from "../components/Services";
import WhatDo from "../components/WhatDo";

const Home = () => {
  console.log(process.env);
  return (
    <>
      <HomeContainer />
      <ClientSlider />
      <OurMission />
      <Services />
      <Portfolio />
      <WhatDo />
      <Faqs />
      <Reviews />
    </>
  );
};

export default Home;
