import './App.css';
import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import RouteConfig from "./routes/RouteConfig";
import "aos/dist/aos.css";
const AOS = require("aos");

const App = () => {
  useEffect(() => {
    AOS.init(
      {
        offset: 200,
        duration: 800,
        easing: "ease-in-out-sine",
        delay: 200,
        mirror: true,
      },
      []
    );
  });
  AOS.init();
  return (
    <Router>
      <RouteConfig />
    </Router>
  );
};

export default App;
