import React from "react";
import GradientHeader from "./../shared/GradientHeader";
import AppHero from "./../components/AppHero";

const AppDetails = () => {
  return (
    <div>
      <GradientHeader
        heading={"App Development"}
        text={"Services"}
        activeText={"Mobile Apps"}
      />
      <AppHero />
    </div>
  );
};

export default AppDetails;
