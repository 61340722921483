import React from "react";
import SoftwareHero from "../components/SoftwareHero";
import GradientHeader from "../shared/GradientHeader";

const SoftwareDetails = () => {
  return (
    <div>
      <GradientHeader
        heading={"Software Development"}
        text={"Services"}
        activeText={"Software"}
      />
      <SoftwareHero />
    </div>
  );
};

export default SoftwareDetails;
