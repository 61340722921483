import React from "react";
import SmartContractHero from "../components/SmartContractHero";
import GradientHeader from "../shared/GradientHeader";

const SmartContractDetails = () => {
  return (
    <div>
      <GradientHeader
        heading={"Smart Contracts"}
        text={"Services"}
        activeText={"Smart Contracts"}
      />
      <SmartContractHero />
    </div>
  );
};

export default SmartContractDetails;
