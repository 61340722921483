import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { servicesTextData } from "../Data";
import "../assets/css/GamingDetails.css";

const GameServicesDescription = () => {
  return (
    <div className="game-desc-container">
      <Container>
        <Row className="d-flex justify-content-center">
          {servicesTextData?.map((data, index) => (
            <Col
              sx="12"
              sm="12"
              md="10"
              lg="8"
              key={index}
              data-aos="fade-down-right"
            >
              <div className="game-desc-card">
                <h5>{data?.heading}</h5>
                <p>{data?.desc1}</p>
                <p>{data?.desc2}</p>
                <p>{data?.desc3}</p>
                <p>{data?.desc4}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default GameServicesDescription;
