import React from "react";
import GraphicHero from "../components/GraphicHero";
import GradientHeader from "../shared/GradientHeader";

const GraphicDetails = () => {
  return (
    <div>
      <GradientHeader
        heading={"Graphics"}
        text={"Services"}
        activeText={"Graphics"}
      />
      <GraphicHero />
    </div>
  );
};

export default GraphicDetails;
