import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import GameImg from "../assets/images/game1.png";
import "../assets/css/GamingDetails.css";
import { useNavigate } from "react-router-dom";

const GameServicesEnd = () => {
  const navigate = useNavigate();
  return (
    <div className="game-end-container" data-aos="zoom-in-down">
      <Container>
        <Row className="game-end-row">
          <Col sx="12" md="12" lg="6">
            <div className="game-end-left">
              <h3>
                Aethon Int - The Partner You Need For NFT Game Development
              </h3>
              <p>
                Aethon Int. embodies the concept of NFT games with joy. and
                excitement. Any clients partnering with Aethon know how much
                attention and effort goes into each game concept. During the
                concept phase, our inspiring team will lock the game concept to
                ensure longevity for your users to enjoy.
              </p>
              <button onClick={() => navigate("/contact-us")}>
                Let's Chat
              </button>
            </div>
          </Col>
          <Col sx="12" md="12" lg="6">
            <Image src={GameImg} className="nft-img" rounded />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GameServicesEnd;
