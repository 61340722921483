import React from "react";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import CustomButton from "./../shared/CustomButton";
import { useNavigate } from "react-router-dom";
import "../assets/css/SoftwareDetails.css";

const SoftwareHero = () => {
  const navigate = useNavigate();
  return (
    <>
      {/*Discord Details Hero */}
      <div className="software-details-container">
        <Container className="home-container ">
          <Row className="home-row  services-hero-row">
            <Col
              xs="12"
              sm="12"
              md="8"
              lg="6"
              data-aos="fade-down-right"
              data-aos-anchor-placement="top-bottom"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <div className="home-left-column">
                <h6>SOFTWARE</h6>
                <h1>Software Development</h1>
                <p>
                  Create complex enterprise software, ensure reliable software
                  integration, modernise your legacy system.
                </p>
                <CustomButton
                  variant="success"
                  btnClick={() => navigate("/contact-us")}
                  style={{ width: "320px" }}
                >
                  Connect With Our Experts{" "}
                </CustomButton>
              </div>
            </Col>
            <Col xs="12" sm="12" md="12" lg="6"></Col>
          </Row>
        </Container>
      </div>
      <div className="software-card-container" data-aos="zoom-in-up">
        <Container>
          {/* <h3 className="text-center">Designing Process We Follow</h3> */}
          <Row>
            <Col sm="12" md="6" lg="6">
              <div className="software-text-card">
                <h5>Our Development Process</h5>
                <p>
                  We have built our entire process of client engagement based on
                  our core values of accountability and communication. We’ve
                  innovated several custom, online tools to support this
                  process, which has significantly improved synchronicity of our
                  team with our customers. We believe in real-time.
                </p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="6">
              <div className="software-text-card">
                <h5>Mobile App Development Experts</h5>
                <p>
                  As a leading development company in mobile applications and
                  web applications, our dedicated teams of senior software
                  developers, designers, and project managers build
                  cutting-edge, digital solutions for iOS and Android. We are
                  your partners in digital transformation.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="software-services-card-container">
        <h3 className="container text-center">
          Why Aethon for Software Development Services?
        </h3>
        <p className="container text-center">
          With our team of experts, we give your go-to-market strategy wings and
          cater to your needs with knowledge, passion and speed.
        </p>
        <Container data-aos="zoom-in-up">
          <Row className=" software-row">
            <Col xs="12" md="6" lg="6">
              <div className="software-services-card">
                <h5>Get Access to Top Developers</h5>
                <p>
                  Hire skilled and qualified tech professionals from our
                  extensive talent pool of developers to create a high-quality
                  software product.
                </p>
              </div>
            </Col>
            <Col xs="12" md="6" lg="6">
              <div className="software-services-card">
                <h5>Experienced IT Recruiters</h5>
                <p>
                  Don’t waste your time on recruiting – our internationally
                  certified IT recruiters will find and screen candidates to
                  select the best-matching ones.
                </p>
              </div>
            </Col>
            <Col xs="12" md="6" lg="6">
              <div className="software-services-card">
                <h5>Save Money & Increase Efficiency</h5>
                <p>
                  A team of professional developers driven by talent and
                  expertise allows you to cut down your operational cost. You’ll
                  be able to focus on your core business strategies while
                  increasing cost-efficiency. We take care of the development.
                </p>
              </div>
            </Col>
            <Col xs="12" md="6" lg="6">
              <div className="software-services-card">
                <h5>Get a Trustworthy Partner</h5>
                <p>
                  There’s no hidden cost. You’ll pay a fixed fee for the
                  services you choose – our transparent pricing policy lets you
                  plan your budget down to the last detail. You only pay for the
                  expertise you need.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SoftwareHero;
