import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import { IoStarSharp } from 'react-icons/io5';

import DummyImg from '../assets/images/what.jpg';

const SuccessStories = () => {

    const storiesData = [
        { img: DummyImg, name: 'Testing User', review: 'Offering Courses the excitement they want in their games. Leverage NFT Game Development for credible and secure game assets.' },
        { img: DummyImg, name: 'Testing User', review: 'Offering Courses the excitement they want in their games. Leverage NFT Game Development for credible and secure game assets.' },
        { img: DummyImg, name: 'Testing User', review: 'Offering Courses the excitement they want in their games. Leverage NFT Game Development for credible and secure game assets.' },
        { img: DummyImg, name: 'Testing User', review: 'Offering Courses the excitement they want in their games. Leverage NFT Game Development for credible and secure game assets.' },
        { img: DummyImg, name: 'Testing User', review: 'Offering Courses the excitement they want in their games. Leverage NFT Game Development for credible and secure game assets.' },
    ];

    return (
        <div className='success-container'>
            <Container className="services-container my-5">
                <h1 className='success-heading'>Success Stories</h1>
                <Row className="services-row">
                    <Col
                        xs="12"
                    >
                        <Carousel
                            additionalTransfrom={0}
                            arrows={false}
                            showDots={false}
                            autoPlay={true}
                            autoPlaySpeed={3000}
                            centerMode={false}
                            className=""
                            containerClass="container-with-dots"
                            dotListClass=""
                            draggable
                            focusOnSelect={false}
                            infinite={true}
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                                desktop: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 1024,
                                    },
                                    items: 1,
                                    partialVisibilityGutter: 60,
                                },
                                mobile: {
                                    breakpoint: {
                                        max: 600,
                                        min: 0,
                                    },
                                    items: 1,
                                    partialVisibilityGutter: 30,
                                },
                                tablet: {
                                    breakpoint: {
                                        max: 1024,
                                        min: 601,
                                    },
                                    items: 1,
                                    partialVisibilityGutter: 30,
                                },
                            }}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                        >
                            {storiesData.map((story, index) => {
                                return <div key={index} className='text-center'>
                                    <Image fluid src={story.img} loading='lazy' width={100} className='rounded my-3' />
                                    <div>
                                        <IoStarSharp className='review-star' />
                                        <IoStarSharp className='review-star' />
                                        <IoStarSharp className='review-star' />
                                        <IoStarSharp className='review-star' />
                                        <IoStarSharp className='review-star' />
                                    </div>
                                    <p className='text-center name-text'>{story.name}</p>
                                    <p className='text-center'>{story.review}</p>
                                </div>
                            })}
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SuccessStories;
