import React from "react";
import ArtHero from "../components/ArtHero";
import GradientHeader from "../shared/GradientHeader";

const ArtDetails = () => {
  return (
    <div>
      <GradientHeader
        heading={"Artwork"}
        text={"Services"}
        activeText={"Artwork"}
      />
      <ArtHero />
    </div>
  );
};

export default ArtDetails;
