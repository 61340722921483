import React from "react";
import WebsiteHero from "../components/WebsiteHero";
import GradientHeader from "../shared/GradientHeader";

const WebsiteDetails = () => {
  return (
    <div>
      <GradientHeader
        heading={"Websites"}
        text={"Services"}
        activeText={"Websites"}
      />
      <WebsiteHero />
    </div>
  );
};

export default WebsiteDetails;
