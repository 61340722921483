import React from "react";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import CustomButton from "./../shared/CustomButton";
import { useNavigate } from "react-router-dom";
import "../assets/css/Metaverse.css";

const Metaverse = () => {
  const navigate = useNavigate();
  return (
    <div className="metaverse-details-container">
      <Container className="home-container ">
        <h1 className="text-center" style={{ color: "white" }}>
          COMING SOON
        </h1>
        <Row className="home-row  services-hero-row">
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="6"
            data-aos="fade-down-right"
            data-aos-anchor-placement="top-bottom"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <div className="home-left-column">
              {/* <h6>DESIGN</h6> */}

              {/* <p>
                At Aethon we love nothing more than transforming imagination
                into reality. Our team specialise in branding, packaging,
                illustration & advertising. So, whether you are looking to
                convey ideas in beautiful simplicity or with mind-blowing
                visuals, we'll create something that will make your audience
                salivate.
              </p>
              <CustomButton
                variant="success"
                btnClick={() => navigate("/contact-us")}
                style={{ width: "320px" }}
              >
                Connect With Our Experts{" "}
              </CustomButton> */}
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="6"
            data-aos="zoom-in-up"
            data-aos-anchor-placement="top-bottom"
          ></Col>
        </Row>
      </Container>
    </div>
  );
};

export default Metaverse;
