import React from "react";
import Team from "../components/Team";
import GradientHeader from "../shared/GradientHeader";
import ClientSlider from "./../components/ClientSlider";
import OurMission from "./../components/OurMission";
import WhatDo from "./../components/WhatDo";

const AboutUs = () => {
  return (
    <div>
      <GradientHeader heading={"About Us"} activeText={"About"} />
      <div style={{ paddingTop: "10px" }}>
        <OurMission />
        <div className="about-client-slider">
          <ClientSlider />
        </div>

        <WhatDo />
      </div>
    </div>
  );
};

export default AboutUs;
