import React from "react";
import GradientHeader from "./../shared/GradientHeader";
import Services from "./../components/Services";
import ClientSlider from "./../components/ClientSlider";
import Reviews from "./../components/Reviews";

const OurServices = () => {
  return (
    <div>
      <GradientHeader heading={"Our Services"} activeText={"Services"} />
      <div style={{ padding: "10px 0" }}>
        <ClientSlider />
        <div className="services-page-container">
          <Services />
        </div>
      </div>
      <Reviews />
    </div>
  );
};

export default OurServices;
