import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "../assets/css/WhatDo.css";
import CustomHeader from "../shared/CustomHeader";
import MissionImg from "../assets/images/mission.jpg";

const WhatDo = () => {
  return (
    <Container className="what-do-container mobile-what-container">
      <Row className="px-0 what-we-do-row">
        <Col
          sx="12"
          sm="12"
          md="12"
          lg="6"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-easing="linear"
          data-aos-duration="500"
        >
          <div className="what-do-column-right">
            <CustomHeader
              text={"WHAT WE DO"}
              heading={"What result will you get."}
            />
            <div>
              <p>
                Blockchain Technology is an advancement in technology that is
                expected to do wonders in the coming years. Non-fungible tokens
                are considered to be the best of blockchain technology so far.
                This is because of the unique characteristics that non-fungible
                tokens possess. Non-fungibility itself is the reason for its
                uniqueness. It is represented with a unique ID that cannot be
                duplicated or divided.
              </p>
              <p>
                This term, non-fungible tokens have been going around for a
                while now. The volume of the funds revolving around it has also
                been gargantuan.
              </p>
              <p>
                People are investing humongous funds in it, considering its
                security traits and the ownership rights it provides to
                creators. As the usage of NFTs cannot be confined to a single
                industry, we at Inoru have equipped ourselves with variant NFT
                development services. If you are an entrepreneur watching the
                NFT space closely for some time, it's time you join hands with
                us to develop your NFT solution.
              </p>
            </div>
          </div>
        </Col>
        <Col
          sx="12"
          sm="12"
          md="12"
          lg="6"
          data-aos="zoom-in-up"
          data-aos-anchor-placement="top-bottom"
        >
          <div class="what-do-right-column">
            <Image
              src={MissionImg}
              className="what-do-img"
              lazyloading="true"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default WhatDo;
