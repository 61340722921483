import React, { useState } from "react";
import Logo from "../assets/images/logo.png";
import "../assets/css/Header.css";
import { Link } from "react-router-dom";
import { RiMenu3Fill, RiArrowDownSLine } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const closeMenu = () => setMenuOpen(false);
  const [services, setServices] = useState(false);
  console.log(services);

  //Handle Navbar Toggle
  const handleToggle = () => {
    setMenuOpen(!menuOpen);
  };
  const showServices = () => {
    setServices(!services);
  };

  return (
    <>
      <div className="nav-container ">
        <div className="nav-inner-container container">
          <div className="nav-brand">
            <Link to="/">
            <img src={Logo} alt="Logo" style={{ width: "120px" }} />
            </Link>
          </div>
          <ul className="nav-list">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about-us" className="nav-link">
                ABOUT US
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/portfolio" className="nav-link">
                OUR WORK
              </Link>
            </li>

            <li className="dropdown nav-item ">
              <Link
                to="/services"
                className="dropdown-toggle nav-link"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Our Services <span className="caret" />
              </Link>

              <ul className="dropdown-menu">
                <li className="nav-item nav-dropdown-link">
                  <Link to="/services/softwares" className="nav-link ">
                    SOFTWARE DEVELOPMENT
                  </Link>
                </li>
                <li className="nav-item nav-dropdown-link">
                  <Link to="/services/websites" className="nav-link ">
                    WEBSITE DEVELOPMENT
                  </Link>
                </li>
                <li className="nav-item nav-dropdown-link">
                  <Link to="/services/gaming" className="nav-link ">
                    NFT GAME DEVELOPMENT
                  </Link>
                </li>
                <li className="nav-item nav-dropdown-link">
                  <Link to="/services/appdevelopment" className="nav-link ">
                    APP DEVELOPMENT
                  </Link>
                </li>

                <li className="nav-item nav-dropdown-link">
                  <Link to="/services/artwork" className="nav-link ">
                    NFT ARTWORK CREATION
                  </Link>
                </li>
                <li className="nav-item nav-dropdown-link">
                  <Link to="/services/graphics" className="nav-link ">
                    GRAPHICS & BRANDING
                  </Link>
                </li>
                <li className="nav-item nav-dropdown-link">
                  <Link to="/services/contract" className="nav-link ">
                    SMART CONTRACTS
                  </Link>
                </li>
                <li className="nav-item nav-dropdown-link">
                  <Link to="/services/marketing" className="nav-link ">
                    DISCORD MARKETING
                  </Link>
                </li>
                <li className="nav-item nav-dropdown-link">
                  <Link to="/services/metaverse" className="nav-link ">
                    METAVERSE (COMING SOON)
                  </Link>
                </li>
              </ul>
            </li>
            <Link to="/contact-us">
              <button className="nav-btn">CONTACT US</button>
            </Link>
          </ul>
          <div className="toggle-icons">
            {menuOpen ? (
              <AiOutlineClose
                size={35}
                className="nav-toggle-icon"
                onClick={handleToggle}
              />
            ) : (
              <RiMenu3Fill
                size={35}
                className="nav-toggle-icon"
                onClick={handleToggle}
              />
            )}
          </div>
        </div>
      </div>
      <div className={` toggle-menu ${menuOpen ? "menu-active" : ""}`}>
        <div className="container" style={{ width: "100%" }}>
          <ul className="nav-toggle--list">
            <li className="nav-toggle-item">
              <Link to="/" className="nav-toggle-link" onClick={handleToggle}>
                Home
              </Link>
            </li>
            <li className="nav-toggle-item">
              <Link
                to="/about-us"
                className="nav-toggle-link"
                onClick={handleToggle}
              >
                ABOUT US
              </Link>
            </li>
            <li className="nav-toggle-item">
              <Link
                to="/portfolio"
                className="nav-toggle-link"
                onClick={handleToggle}
              >
                OUR WORK
              </Link>
            </li>

            <li className="dropdown nav-toggle-item ">
              <Link
                to="/services"
                className=" nav-toggle-link"
                role="button"
                onClick={handleToggle}
              >
                Our Services{" "}
                {/* <span>
                  <RiArrowDownSLine />
                </span> */}
              </Link>
              {services === true ? (
                <ul className="services-dropdown">
                  <li className="nav-toggle-item">
                    <Link
                      to="/services/softwares"
                      className="nav-toggle-link"
                      onClick={handleToggle}
                    >
                      Software Development
                    </Link>
                  </li>
                  <li className="nav-toggle-item">
                    <Link
                      to="/services/websites"
                      className="nav-toggle-link"
                      onClick={handleToggle}
                    >
                      Website DEVELOPMENT
                    </Link>
                  </li>
                  <li className="nav-toggle-item">
                    <Link
                      to="/services/gaming"
                      className="nav-toggle-link"
                      onClick={handleToggle}
                    >
                      NFT GAME DEVELOPMENT
                    </Link>
                  </li>
                  <li className="nav-toggle-item">
                    <Link
                      to="/services/graphics"
                      className="nav-toggle-link"
                      onClick={handleToggle}
                    >
                      GRAPHICS & BRANDING
                    </Link>
                  </li>
                  <li className="nav-toggle-item">
                    <Link
                      to="/services/appdevelopment"
                      className="nav-toggle-link"
                      onClick={handleToggle}
                    >
                      APP DEVELOPMENT
                    </Link>
                  </li>
                  <li className="nav-toggle-item">
                    <Link
                      to="/services/artwork"
                      className="nav-toggle-link"
                      onClick={handleToggle}
                    >
                      NFT ART WORK
                    </Link>
                  </li>
                  <li className="nav-toggle-item">
                    <Link
                      to="/services/contract"
                      className="nav-toggle-link"
                      onClick={handleToggle}
                    >
                      SMART CONTACTS
                    </Link>
                  </li>
                  <li className="nav-toggle-item">
                    <Link
                      to="/services/marketing"
                      className="nav-toggle-link"
                      onClick={handleToggle}
                    >
                      DISCORD MARKETING
                    </Link>
                  </li>
                  <li className="nav-toggle-item last-child">
                    <Link
                      to="/services/metaverse"
                      className="nav-toggle-link"
                      onClick={handleToggle}
                    >
                      METAVERSE
                    </Link>
                  </li>
                </ul>
              ) : null}
            </li>
            <li className="nav-toggle-item " onClick={handleToggle}>
              <Link to="/contact-us" className="nav-toggle-link ">
                CONTACT US
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
