import React from "react";
import "react-photo-view/dist/react-photo-view.css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Container, Row, Col, Image } from "react-bootstrap";
import "../assets/css/WorkDetail.css";
import { useNavigate } from "react-router-dom";

const WorkDetailsComponent = ({ data }) => {
  const navigate = useNavigate();
  if (data?.length <= 0) {
    return (
      <h1
        style={{
          color: "white",
          textAlign: "center",
          padding: "30px 0",
          height: "30vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        COMING SOON
      </h1>
    );
  }

  return (
    <div>
      <Container>
        <Row className="mt-5">
          {data?.map((data, index) => (
            <Col sx="12" md="6" lg="4" key={index} data-aos="zoom-in-up">
              <div className="work-detail-card">
                <div className="work-detail-img-div">
                  <PhotoProvider>
                    <PhotoView key={index} src={data?.img}>
                      <Image
                        src={data?.prevImg}
                        alt={data?.title}
                        lazyloading="true"
                        style={{ width: "100%" }}
                      />
                    </PhotoView>
                  </PhotoProvider>
                </div>

                <h6>{data?.title}</h6>
                {data?.link && (
                  <button
                    className=" visit-btn"
                    onClick={() => navigate(`/our-work/nft/${data?.id}`)}
                  >
                    View More
                  </button>
                )}
                {/* <p>{data?.desc}</p> */}
                {/* <div className="d-flex align-items-center justify-content-center">
                  <PhotoProvider>
                    <PhotoView key={index} src={data?.img}>
                      <button className="preview-btn ">Preview</button>
                    </PhotoView>
                  </PhotoProvider>
                  {data?.link && (
                    <>
                      <a href={data?.link} target="_blank">
                        <button className="visit-btn ">Visit</button>
                      </a>
                    </>
                  )}
                </div> */}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default WorkDetailsComponent;
