import React from "react";
import ContactForm from "../components/ContactForm";
import GradientHeader from "./../shared/GradientHeader";

const ContactUs = () => {
  return (
    <div>
      <GradientHeader heading={"CONTACT US"} activeText={"Contact"} />
      <ContactForm />
    </div>
  );
};

export default ContactUs;
