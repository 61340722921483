import React from "react";
import { Container, Image, Col, Row } from "react-bootstrap";
import "../assets/css/Portfolio.css";
import { ourWorkListData } from "../Data";
import { useNavigate } from "react-router-dom";

const Portfolio = () => {
  const navigate = useNavigate();
  return (
    <Container className="portfolio-container">
      <h5>OUR WORK</h5>
      <h3>See Our Latest & Popular Projects.</h3>
      <Row
        className="portfolio-row"
        data-aos="zoom-in-up"
        data-aos-anchor-placement="top-center"
      >
        {ourWorkListData?.map((data, index) => (
          <Col xs="12" md="6" lg="4" key={index}>
            <div
              className="mx-auto portfolio-card"
              onClick={() => navigate(`/our-work/${data?.id}`)}
            >
              <Image
                src={data?.img}
                alt="choose-card-item"
                className="portfolio-img"
              />
              <h5>{data?.heading}</h5>
              <p>{data?.text}</p>
              <button onClick={() => navigate(`/our-work/${data?.id}`)}>
                View More
              </button>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Portfolio;
