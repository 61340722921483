import React, { useEffect, useState } from "react";
import GradientHeader from "./../shared/GradientHeader";
import { useParams } from "react-router-dom";
import { ntfDetailsPageData } from "../Data";
import NftDetailsImage from "../components/NftDetailsImage";

const NftDetailPage = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    let fetchedData = ntfDetailsPageData.find((data) => data?.id === id);
    setData(fetchedData);
  }, [id]);

  return (
    <div>
      <GradientHeader
        heading={data?.title}
        text={"Our Work"}
        activeText={"NFT Artwork"}
      />
      <NftDetailsImage data={data?.imgages} />
    </div>
  );
};

export default NftDetailPage;
