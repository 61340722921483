import React from "react";
import { Container, Row, Col, Image, Accordion } from "react-bootstrap";
import CustomButton from "../shared/CustomButton";
import "../assets/css/WebsiteDetails.css";
import { useNavigate } from "react-router-dom";

const WebsiteHero = () => {
  const navigate = useNavigate();
  return (
    <>
      {/*Discord Details Hero */}
      <div className="websites-details-container website-banner">
        <Container className="home-container ">
          <Row className="home-row  services-hero-row">
            <Col
              xs="12"
              sm="12"
              md="8"
              lg="6"
              data-aos="fade-down-right"
              data-aos-anchor-placement="top-bottom"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <div className="home-left-column">
                <h6>WEBSITES</h6>
                <h1>Web Application Development</h1>
                <p>
                  Our Agile experts can create bespoke applications, platforms
                  and products to meet the requirements of your business or new
                  venture.
                </p>
                <CustomButton
                  variant="success"
                  btnClick={() => navigate("/contact-us")}
                  style={{ width: "330px" }}
                >
                  Connect With Our Experts{" "}
                </CustomButton>
              </div>
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="6"
              data-aos="zoom-in-up"
              data-aos-anchor-placement="top-bottom"
            ></Col>
          </Row>
        </Container>
      </div>
      {/* web Details Second Section */}
      <div className="website-card-container" data-aos="fade-up">
        <Container>
          <h3 className="text-center">Web App Development Process We Follow</h3>
          <Row className=" web-row">
            <Col sm="12" md="6" lg="6" data-aos="zoom-in-up">
              <div className="website-text-card">
                <h5>Responsive web applications</h5>
                <p>
                  All the web applications we build are responsive. This means
                  they look great and work perfectly on any screen of any size;
                  desktop, table or smartphone. We do this by adapting menus,
                  controls and other visuals to fit the device based on the
                  current screen width and height, ensuring the user always gets
                  the best possible experience regardless of what device they’re
                  using.
                </p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="6" data-aos="zoom-in-up">
              <div className="website-text-card">
                <h5>Web application technology and development process</h5>
                <p>
                  At the front end, we use browser-based technology such as
                  HTML5, served by .NET Core and C# programming. We implement
                  Web3 also. We combine the very best technical platforms,
                  including Microsoft Azure, SQL Server and Web APIs. We also
                  have expertise in JavaScript frameworks like VueJS, React &
                  Angular – the scripts that create interactivity on the web.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="web-why-us-container">
        <Container className="why-us">
          <h3>Web Applications</h3>
          <p style={{ textAlign: "start" }}>
            People’s working hours are no longer 9 – 5, Monday to Friday. They
            expect to be able to work and access information from a company when
            they need it from wherever they are; a web application offers
            companies a flexible, cost-effective way to meet this need. From a
            streamlined version of an internal system to increasing the
            efficiency of remote or travelling employees, to an interactive tool
            to help build brand loyalty with customers; a web application can
            deliver. Other web application examples include:
          </p>
          <li
            style={{
              color: "white",
              marginBottom: "0.5rem",
              listStyle: "none",
            }}
          >
            An internal web application to help manage projects & finances.
          </li>
          <li
            style={{
              color: "white",
              marginBottom: "0.5rem",
              listStyle: "none",
            }}
          >
            A web application that you can re-sell to a customer –
            Software-as-a-Service.
          </li>
          <li
            style={{
              color: "white",
              marginBottom: "0.5rem",
              listStyle: "none",
            }}
          >
            An application to revolutionise customer service by providing a
            portal for your customers to access.
          </li>
          <li
            style={{
              color: "white",
              marginBottom: "0.5rem",
              listStyle: "none",
            }}
          >
            We discover everything there is to learn about your business, what a
            new app needs to deliver and to whom. If you’d like us to, we’ll
            also suggest a few of our own ideas – we love coming up with fresh
            solutions that really make the technology work hard.
          </li>
        </Container>
      </div>
    </>
  );
};

export default WebsiteHero;
