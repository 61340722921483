import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WorkDetailsComponent from "../components/WorkDetailsComponent";
import { ourWorkListData } from "../Data";
import GradientHeader from "./../shared/GradientHeader";

const WorkDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    const filteredData = ourWorkListData?.find((data) => data?.id === id);
    setData(filteredData);
  }, [id]);
  return (
    <div>
      <GradientHeader
        heading={`${data?.heading} `}
        text={"Our Work"}
        activeText={`${data?.heading}`}
      />
      <WorkDetailsComponent data={data?.details} />
    </div>
  );
};

export default WorkDetails;
