import React from "react";
import GradientHeader from "../shared/GradientHeader";
import GamingHero from "../components/GamingHero";

const GamingDetails = () => {
  return (
    <div>
      <GradientHeader
        heading={"Game Creation"}
        text={"Services"}
        activeText={"Gaming"}
      />
      <GamingHero />
    </div>
  );
};

export default GamingDetails;
