import React from 'react';

import EnrollTop from '../components/EnrollTop';
import GradientHeader from '../shared/GradientHeader';
import CoursesOffered from '../components/CoursesOffered';
import SuccessStories from '../components/SuccessStories';
import EnrollForm from '../components/EnrollForm';

const EnrollNow = () => {
    return (
        <React.Fragment>
            <GradientHeader heading={"OFFER COURSES"} />
            <EnrollTop />
            <CoursesOffered />
            <SuccessStories />
            <EnrollForm />
        </React.Fragment>
    )
}

export default EnrollNow;
