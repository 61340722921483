import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

import EnrollImg from '../assets/images/enroll-form-attachment.png';
import TextField from '../shared/TextField';

const EnrollForm = () => {

    /* Setting the initial values of the form. */
    const initialValues = {
        name: "",
        whatsapp: "",
        email: "",
        course: "Freelancing",
    };
    /* A validation schema for the form. */
    const errorSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        whatsapp: Yup.string().required("Whatsapp is required"),
        email: Yup.string().email().required("Email is required"),
        course: Yup.string().required("Course is required"),
    });

    /**
     * The handleSubmit function takes in two arguments, values and resetForm. The values argument is
     * an object that contains all the values from the form. The resetForm argument is a function that
     * resets the form to its initial state
     * @param values - An object containing all the values from the form.
     */
    const handleSubmit = (values, { resetForm }) => {
        console.log({ values });
    };

    return (
        <Container className='my-5'>
            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs='12' lg='6'>
                    <Image fluid src={EnrollImg} alt='Enroll' loading='lazy' />
                </Col>
                <Col xs='12' lg='6'>
                    <h5 className='enrol-form-heading'>Enroll Yourself Now</h5>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={errorSchema}
                        onSubmit={handleSubmit}
                    >
                        {(formik) => (
                            <Form>
                                <TextField
                                    label="Name"
                                    type="text"
                                    name="name"
                                    placeholder="Enter Your Name"
                                />
                                <TextField
                                    label="Whatsapp No"
                                    type="text"
                                    name="whatsapp"
                                    placeholder="Enter Whatsapp No"
                                />
                                <TextField
                                    label="Email"
                                    type="email"
                                    name="email"
                                    placeholder="Enter Your Email"
                                />
                                <div className="mb-3">
                                    <label className="form-label">Select Course</label>
                                    <Field name="course" component="select" className="form-control">
                                        <option value="Freelancing">Freelancing</option>
                                        <option value="Graphics Designing">Graphics Designing</option>
                                        <option value="Web Designing">Web Designing</option>
                                        <option value="UI/UX">UI/UX</option>
                                        <option value="Digital Marketing">Digital Marketing</option>
                                        <option value="Virtual Assistant">Virtual Assistant</option>
                                        <option value="E Commerce">E Commerce</option>
                                        <option value="Video & Photo Editing">Video & Photo Editing</option>
                                    </Field>
                                    <ErrorMessage
                                        component="small"
                                        name="course"
                                        className="text-danger"
                                    />
                                </div>
                                <button className="contact-btn">Submit</button>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    )
}

export default EnrollForm;
