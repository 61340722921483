import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import MissionImg from "../assets/images/mission.jpeg";
import "../assets/css/Mission.css";
import CustomHeader from "../shared/CustomHeader";
import { missionData } from "../Data";

const OurMission = () => {
  return (
    <Container className="mission-container">
      <Row className="px-0">
        <Col
          sx="12"
          sm="12"
          md="12"
          lg="6"
          className="mission-column-left"
          data-aos="zoom-in-up"
          data-aos-anchor-placement="top-bottom"
        >
          <Image
            src={MissionImg}
            className="mission-img"
            lazyloading="true"
            alt="mission-img"
          />
        </Col>
        <Col
          sx="12"
          sm="12"
          md="12"
          lg="6"
          className="mission-column-right"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-easing="linear"
          data-aos-duration="500"
        >
          <CustomHeader
            text={" Design. Development. Consultancy."}
            heading={`  Widen Your Reach in the Future Web3 Realm.`}
          />
          <p>
            Develop results-driven products for entrepreneurs, startups, and
            enterprises with a leading software development company.
          </p>
          <div className="text-row ">
            {missionData?.map((data, index) => (
              <div key={index} className="mission-text-container ">
                <span>{data?.icon}</span>
                <div className="mission-inner-text">
                  <h5>{data?.heading}</h5>
                  <p>{data?.text}</p>
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default OurMission;
