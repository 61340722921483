import React from "react";
import { ErrorMessage, useField } from "formik";

const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  if (props.textarea) {
    return (
      <div className="mb-3">
        {label && <label className="form-label">{label}</label>}
        <textarea
          className={`form-control shadow-none ${
            meta.touched && meta.error && "is-invalid"
          }`}
          {...field}
          {...props}
          min="4"
          rows="6"
        ></textarea>
        <ErrorMessage
          component="small"
          name={field.name}
          className="text-danger"
        />
      </div>
    );
  } else {
    return (
      <div className="mb-3">
        {label && <label className="form-label">{label}</label>}
        <input
          className={`form-control shadow-none ${
            meta.touched && meta.error && "is-invalid"
          }`}
          {...field}
          {...props}
        />
        <ErrorMessage
          component="small"
          name={field.name}
          className="text-danger"
        />
      </div>
    );
  }
};

export default TextField;
