import React from 'react';
import { Container } from 'react-bootstrap';
import '../assets/css/CustomHeader.css';

const CustomHeader = ({ text, heading }) => {
  return (
    <Container fluid className='custom-header-container px-0'>
      <h5>{text}</h5>
      <h3>{heading}</h3>
    </Container>
  );
};

export default CustomHeader;
