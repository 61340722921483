import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { BsFacebook, BsTwitter, BsInstagram } from "react-icons/bs";
import Logo from "../assets/images/logo.png";
import "../assets/css/Footer.css";
import { TbBrandTelegram } from "react-icons/tb";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <Container className="footer-container">
        <Row className="footer-row">
          <Col xs="12" md="6" lg="3" className="footer-col">
            <Image src={Logo} alt="logo" style={{ width: "120px" }} />
            <p className="footer-text">
              Your One Stop Shop For All Things Web3.
            </p>
            <div className="d-flex align-items-center social-icons-div ">
              <div className="footer-icon-div">
                <a
                  href="https://www.facebook.com/aethon.digital"
                  target="_blank"
                >
                  <BsFacebook size={20} className="footer-icon " />
                </a>
              </div>
              <div className="footer-icon-div ml-3">
                <a href="https://twitter.com/AethonInt" target="_black">
                  <BsTwitter size={20} className="footer-icon " />
                </a>
              </div>

              <div className="footer-icon-div ml-3">
                <a
                  href="https://www.instagram.com/aethonintuk/"
                  target="_black"
                >
                  <BsInstagram size={20} className="footer-icon " />
                </a>
              </div>
            </div>
          </Col>
          <Col xs="12" md="6" lg="3" className="footer-col">
            <h3>Navigations</h3>
            <Link to="/services/artwork">
              <p>Artwork</p>
            </Link>
            <Link to="/about-us">
              {" "}
              <p>About Us</p>
            </Link>
            <Link to="/portfolio">
              {" "}
              <p>Our Work</p>
            </Link>
            <Link to="/contact-us">
              {" "}
              <p>Contact Us</p>
            </Link>
            <Link to="/services">
              {" "}
              <p>Our Services</p>
            </Link>
          </Col>
          <Col xs="12" md="6" lg="3" className="footer-col">
            <h3>Quick Links</h3>
            <Link to="/services/gaming">
              <p>Gaming</p>
            </Link>
            <Link to="/services/graphics">
              <p>Graphics</p>
            </Link>
            <Link to="/services/websites">
              <p>Websites</p>
            </Link>

            <Link to="/services/contract">
              <p>Smart Contracts</p>
            </Link>
            <Link to="/services/marketing">
              <p>Discord Marketing</p>
            </Link>
          </Col>
          <Col xs="12" md="6" lg="3" className="footer-col">
            <div className="address-container footer-col">
              <h3>Location</h3>
              <p className="location-text">
                Aethon int ltd , 5 Brayford Square , LONDON E1 0SG
              </p>
              <p className="location-text">Registration Number : 14275264</p>
              <p className="location-text">Email Us : hello@aethon.digital</p>
              <a className="text-white" href="tel:92537533033">Call Us : +92 537 533 033</a>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <hr />
        <Container>
          <p style={{ color: "white", textAlign: "center" }}>
            Copyright © 2023 AethonInt
          </p>
        </Container>
      </Container>
    </>
  );
};

export default Footer;
