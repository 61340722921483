import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import CustomButton from "../shared/CustomButton";
import "../assets/css/Home.css";
import HomeImg from "../assets/images/home1.png";
import { useNavigate } from "react-router-dom";

const HomeContainer = () => {
  const navigate = useNavigate();
  return (
    <Container className="home-container ">
      <Row className="home-row">
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="6"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-easing="linear"
          data-aos-duration="500"
        >
          <div className="home-left-column">
            <h6>Welcome to Aethon</h6>
            <h1> Robust, Stable and Secure Web3 Development Solutions</h1>
            <p>
              Our Web3 development company helps a diverse clientele build
              result-driven solutions for different industries.
            </p>
            <CustomButton
              variant="success"
              btnClick={() => navigate("/contact-us")}
            >
              Get Started{" "}
            </CustomButton>
          </div>
        </Col>
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="6"
          data-aos="zoom-in-up"
          data-aos-anchor-placement="top-bottom"
        >
          <Image
            src={HomeImg}
            lazyload="true"
            className="home-img"
            rounded="true"
            alt="home-img"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default HomeContainer;
