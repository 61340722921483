import React from "react";
import { Container, Row, Col, Image, Accordion } from "react-bootstrap";
import "../assets/css/Faq.css";
import { faqsData } from "../Data";
import PageHeader from "../shared/CustomHeader";
import MissionImg from "../assets/images/faqs.png";

const Faqs = () => {
  return (
    <Container className="faqs-container">
      <Row>
        <Col
          sx="12"
          md="12"
          lg="6"
          data-aos="zoom-in-up"
          data-aos-anchor-placement="top-bottom"
        >
          <div>
            <Image
              src={MissionImg}
              lazyloading="true"
              alt="faq"
              className="faq-img"
            />
          </div>
        </Col>
        <Col
          sx="12"
          md="12"
          lg="6"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-easing="linear"
          data-aos-duration="500"
        >
          <div className="faqs-column-right">
            <PageHeader text="OUR FAQS" heading="Frequently Asked Questions." />
            <div className="faq-row">
              {faqsData?.map((data, index) => (
                <Accordion flush key={index}>
                  <Accordion.Item eventKey={data?.id.toString()}>
                    <Accordion.Header>{data?.text}</Accordion.Header>
                    <Accordion.Body>{data?.desc}</Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Faqs;
