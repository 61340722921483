import React from "react";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Image } from "react-bootstrap";

const NftDetailsImage = ({ data }) => {
  console.log(data);
  return (
    <Container className="my-5">
      <Row className="px-0 mx-0">
        {data?.map((data) => (
          <Col lg="3" md="4" xs="6" className="px-0 mx-0">
            <div>
              <Image src={data?.img} alt="ngt-img" style={{ width: "100%" }} />
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default NftDetailsImage;
