import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import DiscordDetails from "../pages/DiscordDetails";
import Home from "../pages/Home";
import OurPortfolio from "../pages/Portfolio";
import OurServices from "../pages/Services";
import Footer from "../shared/Footer";
import Navbar from "../shared/Navbar";
import GamingDetails from "../pages/GamingDetails";
import WebsiteDetails from "./../pages/WebsiteDetails";
import ArtDetails from "./../pages/ArtDetails";
import GraphicDetails from "../pages/GraphicDetails";
import SmartContractDetails from "../pages/SmartContractDetails";
import WorkDetails from "../pages/WorkDetails";
import SoftwareDetails from "../pages/SoftwareDetails";
import MetaverseDetails from "../pages/MetaverseDetails";
import AppDetails from "../pages/AppDetails";
import NavHeader from "../shared/Header";
import NftDetailPage from "../pages/NftDetailPage";
import EnrollNow from "../pages/EnrollNow";

const RouteConfig = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <NavHeader />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/enroll-now" element={<EnrollNow />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<OurServices />} />
        <Route path="/services/marketing" element={<DiscordDetails />} />
        <Route path="/services/gaming" element={<GamingDetails />} />
        <Route path="/services/websites" element={<WebsiteDetails />} />
        <Route path="/services/softwares" element={<SoftwareDetails />} />
        <Route path="/services/artwork" element={<ArtDetails />} />
        <Route path="/services/graphics" element={<GraphicDetails />} />
        <Route path="/services/contract" element={<SmartContractDetails />} />
        <Route path="/services/metaverse" element={<MetaverseDetails />} />
        <Route path="/services/appdevelopment" element={<AppDetails />} />
        <Route path="/portfolio" element={<OurPortfolio />} />
        <Route path="/our-work/:id" element={<WorkDetails />} />
        <Route path="/our-work/:id/:id" element={<NftDetailPage />} />
      </Routes>
      <Footer />
    </>
  );
};

export default RouteConfig;
