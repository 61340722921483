import React from "react";
import GradientHeader from "./../shared/GradientHeader";
import ClientSlider from "./../components/ClientSlider";
import Portfolio from "./../components/Portfolio";
import Reviews from "./../components/Reviews";

const OurPortfolio = () => {
  return (
    <div>
      <GradientHeader heading={"Our Work"} activeText="Our Work" />
      <div style={{ padding: "10px 0" }}>
        <ClientSlider />
        <Portfolio />
      </div>
      <Reviews />
    </div>
  );
};

export default OurPortfolio;
