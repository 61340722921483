import { ImQuotesRight } from "react-icons/im";
import { BsCheckCircle } from "react-icons/bs";
import SoftSer from "./assets/images/soft-ser.png";
import WebSer from "./assets/images/web-ser.png";
import NftSer from "./assets/images/nft-ser.png";
import GamesSer from "./assets/images/game-ser.png";
import BrandingSer from "./assets/images/gpahics.png";
import CryptoSer from "./assets/images/crypto-work.png";
import ContractSer from "./assets/images/brand-ser.png";
import Metaser from "./assets/images/metaverse-work.png";
import Gaming from "./assets/images/gaming.png";
import Contract from "./assets/images/contract.png";
import Graphics from "./assets/images/graphics.png";
import Marketing from "./assets/images/marketing.png";
import Art from "./assets/images/art.png";
import Web from "./assets/images/web.png";
import AppIcon from "./assets/images/app-icon.png";
import Software from "./assets/images/software-icon.png";
import Metaverse from "./assets/images/metaverse-icon.png";
import CryptoImg from "./assets/images/crypto.png";
import BuggedImg from "./assets/images/bugged.png";
import Game1 from "./assets/images/game1.png";
import Game2 from "./assets/images/game2.png";
import Server from "./assets/images/Server.png";
import Store from "./assets/images/store.png";
import Content from "./assets/images/content.png";
import Invite from "./assets/images/invite.png";
import Query from "./assets/images/Query.png";
import Events from "./assets/images/Events.png";
import Database from "./assets/images/database.png";
import Promote from "./assets/images/Promote.png";
import Web1 from "./assets/images/web1.png";
import Web2 from "./assets/images/web2.png";
import Web3 from "./assets/images/web3.png";
import Web4 from "./assets/images/web4.jpg";
import Web5 from "./assets/images/web5.png";
import Web6 from "./assets/images/web6.png";
import Web7 from "./assets/images/web7.png";
import Web8 from "./assets/images/web8.png";
import Web9 from "./assets/images/web9.png";
import Web10 from "./assets/images/web10.png";
import Web1P from "./assets/images/web1.jpg";
import Web2P from "./assets/images/web2.jpg";
import Web3P from "./assets/images/web3.jpg";
import Web4P from "./assets/images/web4p.jpg";
import Web5P from "./assets/images/web5.jpg";
import Web6P from "./assets/images/web6.jpg";
import Web7P from "./assets/images/web7.png";
import Web8P from "./assets/images/web8.png";
import Web9P from "./assets/images/web9.png";
import Web10P from "./assets/images/web10.jpg";

import Mob1P from "./assets/images/mob1.jpg";
import Mob2P from "./assets/images/mob2pre.jpg";
import Mob2Pre from "./assets/images/mob2.png";
import Mob3P from "./assets/images/mob3.png";
import Mob4P from "./assets/images/mob4.png";
import Mob5P from "./assets/images/mob5.jpg";
import Mob6P from "./assets/images/mob6.jpg";

import Gamee from "./assets/images/gamee.png";
import Gamee2 from "./assets/images/gamee2.png";
import Gamee3 from "./assets/images/gamee3.png";
import Gamee4 from "./assets/images/gamee4.png";

import Soft1 from "./assets/images/soft1.png";
import Soft2 from "./assets/images/soft2.png";
import Soft3 from "./assets/images/soft3.png";
import Soft4 from "./assets/images/soft4.png";
import Soft5 from "./assets/images/soft5.png";
import Soft6 from "./assets/images/soft6.png";
import Soft7 from "./assets/images/soft7pre.jpg";
import Soft7Pre from "./assets/images/soft7.png";
import Soft8 from "./assets/images/soft8.jpg";
import Soft9 from "./assets/images/soft9pre.jpg";
import Soft10 from "./assets/images/soft10pre.jpg";
import Soft9Pre from "./assets/images/soft9.png";
import Soft10Pre from "./assets/images/soft10.jpg";
import App from "./assets/images/app.png";
import Flaym from "./assets/images/flaym.png";
import Hearte from "./assets/images/hearte.png";
import Gotiket from "./assets/images/gotiket.png";
import Fostec from "./assets/images/fostec.png";
// Bugged Data
import B1 from "./assets/images/bugged/b1.png";
import B2 from "./assets/images/bugged/b2.png";
import B3 from "./assets/images/bugged/b3.png";
import B4 from "./assets/images/bugged/b4.png";
import B5 from "./assets/images/bugged/b5.png";
import B6 from "./assets/images/bugged/b6.png";
import B7 from "./assets/images/bugged/b7.png";
import B8 from "./assets/images/bugged/b8.png";
import B9 from "./assets/images/bugged/b9.png";
import B10 from "./assets/images/bugged/b10.png";
import B11 from "./assets/images/bugged/b11.png";
import B12 from "./assets/images/bugged/b12.png";
import B13 from "./assets/images/bugged/b13.png";
import B14 from "./assets/images/bugged/b14.png";
import B15 from "./assets/images/bugged/b15.png";
import B16 from "./assets/images/bugged/b16.png";
import B17 from "./assets/images/bugged/b17.png";
import B18 from "./assets/images/bugged/b18.png";
import B19 from "./assets/images/bugged/b19.png";
import B20 from "./assets/images/bugged/b20.png";
// Crypto Data
import C1 from "./assets/images/crypto/c1.png";
import C2 from "./assets/images/crypto/c2.png";
import C3 from "./assets/images/crypto/c3.png";
import C4 from "./assets/images/crypto/c4.png";
import C5 from "./assets/images/crypto/c5.png";
import C6 from "./assets/images/crypto/c6.png";
import C7 from "./assets/images/crypto/c7.png";
import C8 from "./assets/images/crypto/c8.png";
import C9 from "./assets/images/crypto/c9.png";
import C10 from "./assets/images/crypto/c10.png";
import C11 from "./assets/images/crypto/c11.png";
import C12 from "./assets/images/crypto/c12.png";
import C13 from "./assets/images/crypto/c13.png";
import C14 from "./assets/images/crypto/c14.png";
import C15 from "./assets/images/crypto/c15.png";
import C16 from "./assets/images/crypto/c16.png";
import C17 from "./assets/images/crypto/c17.png";
import C18 from "./assets/images/crypto/c18.png";
import C19 from "./assets/images/crypto/c19.png";
import C20 from "./assets/images/crypto/c20.png";
// Urban Data
import U1 from "./assets/images/urban/u1.png";
import U2 from "./assets/images/urban/u2.png";
import U3 from "./assets/images/urban/u3.png";
import U4 from "./assets/images/urban/u4.png";
import U5 from "./assets/images/urban/u5.png";
import U6 from "./assets/images/urban/u6.png";
import U7 from "./assets/images/urban/u7.png";
import U8 from "./assets/images/urban/u8.png";
import U9 from "./assets/images/urban/u9.png";
import U10 from "./assets/images/urban/u10.png";
import U11 from "./assets/images/urban/u11.png";
import U12 from "./assets/images/urban/u12.png";
import U13 from "./assets/images/urban/u13.png";
import U14 from "./assets/images/urban/u14.png";
import U15 from "./assets/images/urban/u15.png";
import U16 from "./assets/images/urban/u16.png";
import U17 from "./assets/images/urban/u17.png";
import U18 from "./assets/images/urban/u18.png";
import U19 from "./assets/images/urban/u19.png";
import U20 from "./assets/images/urban/u20.png";
// Goblins Data
import G1 from "./assets/images/goblins/g1.png";
import G2 from "./assets/images/goblins/g2.png";
import G3 from "./assets/images/goblins/g3.png";
import G4 from "./assets/images/goblins/g4.png";
import G5 from "./assets/images/goblins/g5.png";
import G6 from "./assets/images/goblins/g6.png";
import G7 from "./assets/images/goblins/g7.png";
import G8 from "./assets/images/goblins/g8.png";
import G9 from "./assets/images/goblins/g9.png";
import G10 from "./assets/images/goblins/g10.png";
import G11 from "./assets/images/goblins/g11.png";
import G12 from "./assets/images/goblins/g12.png";
import G13 from "./assets/images/goblins/g13.png";
import G14 from "./assets/images/goblins/g14.png";
import G15 from "./assets/images/goblins/g15.png";
import G16 from "./assets/images/goblins/g16.png";
import G17 from "./assets/images/goblins/g17.png";
import G18 from "./assets/images/goblins/g18.png";
import G19 from "./assets/images/goblins/g19.png";
import G20 from "./assets/images/goblins/g20.png";

export const clientsSliderData = [
  {
    img: "https://freevetis.com/bvirtuali/wp-content/uploads/sites/32/2022/05/7496164.png",
  },
  {
    img: "https://freevetis.com/bvirtuali/wp-content/uploads/sites/32/2022/05/6631413.png",
  },
  {
    img: "https://freevetis.com/bvirtuali/wp-content/uploads/sites/32/2022/05/46164631.png",
  },
  {
    img: "https://freevetis.com/bvirtuali/wp-content/uploads/sites/32/2022/05/4614613.png",
  },
];

export const missionData = [
  {
    icon: <BsCheckCircle size={20} />,
    heading: `
    We love transforming product ideas to digital realities.`,
    text: `Three factors help us produce great products: taking
    responsibility for our work, being open about how we work, and
    cultivating long-lasting client connections.`,
  },
  {
    icon: <BsCheckCircle size={20} />,
    heading: `
    We develop enterprise-grade software solutions for businesses.`,
    text: ` Leveraging cutting-edge technologies to build industry-leading
    digital products.`,
  },
  {
    icon: <BsCheckCircle size={20} />,
    heading: `
    14 years of building digital products and we are just getting
    started!`,
    text: `   A full-cycle software, Web3, NFT, game and mobile app
    development company with a world-class team of innovators.`,
  },
];

export const servicesData = [
  {
    id: "softwares",
    img: Software,
    heading: `Software Development`,
    text: `Create complex enterprise software, modernise your legacy system.`,
  },
  {
    id: "websites",
    img: Web,
    heading: `Websites`,
    text: `Got a funky idea for a snazzy website? Let us turn your idea into a reality.`,
  },
  {
    id: "appdevelopment",
    img: AppIcon,
    heading: `App Development`,
    text: `We build digital experiences technical expertise and process-driven development.`,
  },
  {
    id: "graphics",
    img: Graphics,
    heading: `Graphics`,
    text: "Our art team have years of experience creating epic branding for businesses.",
  },
  {
    id: "artwork",
    img: Art,
    heading: `Artwork`,
    text: `Creating high quality bespoke NFT artwork collections to really stand out in the NFT ocean.`,
  },
  {
    id: "gaming",
    img: Gaming,
    heading: `Game Creation`,
    text: `Ever wondered what an idle action fantasy pinball rpg would play like? Let us build it for you!`,
  },
  {
    id: "marketing",
    img: Marketing,
    heading: `Discord Marketing`,
    text: `Need to be heard? Want to build a strong and jam-packed discord server? We can help you!`,
  },
  {
    id: "contract",
    img: Contract,
    heading: `Smart Contract`,
    text: `To sell your NFT collection, you'll need a smart-contract. Let us create that for you.`,
  },
  {
    id: "metaverse",
    img: Metaverse,
    heading: `Metaverse`,
    text: `Coming Soon!`,
  },
];

export const faqsData = [
  {
    id: "1",
    text: " Can you implement NFT collections into games?",
    desc: `Most of the games have in-app products to be purchased already.

    Therefore embedding NFTs will be just an upgrade to this already
    
    existing practice in a more efficient, effortless, and profitable manner.`,
  },

  {
    id: "3",
    text: ` Do you develop NFTs exclusively for virtual assets?`,
    desc: `No, we develop NFTs that can be used in real-world assets too. It

    totally depends on the business project you come up with.`,
  },
  {
    id: "4",
    text: `What does a non-fungible token mean?`,
    desc: `Non-fungible tokens are the digital representation of unique assets

    in the decentralized environment.`,
  },
  {
    id: "5",
    text: `How much does it cost to create an NFT?`,
    desc: `The cost to create an NFT depends on the type of assets, platform

    wealth and its gas fee.`,
  },
  {
    id: "6",
    text: `What are the blockchains you will use to develop NFT?`,
    desc: `We help you develop NFTs on various blockchains like Ethereum,

    TRON, Polkadot, Binance Smart Chain etc.`,
  },
  {
    id: "2",
    text: "Do you offer to create cryptocurrencies along with NFT marketplaces?",
    desc: `Yes, if your business idea is inclusive of a cryptocurrency that

    can be used as a functional coin in the marketplace, we do create them
    
    for you.`,
  },
];

export const reviewsData = [
  {
    text: `We hired Aethon Itn. to do the programming for our VRMMO game & so far, they’ve done a great job. They have been professional, helpful, and to their credit, honest.`,

    name: "Frank Eric",
    designation: "Game Development",
    icon: <ImQuotesRight size={40} />,
  },
  {
    text: `Amazing experience. Art has really an eye for what we were looking for. We gave him a detailed description and it worked out just perfectly for what we have envisioned. I can only recommend his services and it won’t be the last time I will file an order from him. Thank you for your great service Art!`,
    name: "Gene Troost",
    designation: "Art Work",
    icon: <ImQuotesRight size={40} />,
  },
  {
    text: `Such a good experience working with this creative. Super professional and very attentive to details and working out just the right look for the project.`,
    name: "Angela G",
    designation: "Software Development",
    icon: <ImQuotesRight size={40} />,
  },
  {
    text: `Magic is just that - Magic. I absolutely love what he did with this map and we are incredibly thrilled to have worked with him. Very professional and highly skilled.`,
    name: "Jessica Chan",
    designation: "Game Environment",
    icon: <ImQuotesRight size={40} />,
  },
  {
    text: `Extraordinary job by Aethon Int. They have done an excellent job in the custom software development . They adapt it very good to the feeling and look of my company. It goes beyond my expectations. Quick delivery, fast and detailed communication. One of the best experiences. Super recommended.`,
    name: "Micheal",
    designation: "Software Development",
    icon: <ImQuotesRight size={40} />,
  },
];

export const newsData = [
  {
    img: "https://www.blockchainappfactory.com/blog/wp-content/uploads/2022/07/Launch-a-Metaverse-Wallet-to-Transact-Across-Web-3.0-Worlds-to-Own-Virtual-Assets-1200-x-760-copy.jpg",
    heading:
      "Create a Metaverse Wallet to Access Virtual Worlds Across Blockchain Networks!",
    text: "The term metaverse has been creating many ripples since its initial mention. The word was mentioned in the 1992 novel Snow Crash by Neal Stephenson. As blockchain technology grew parallelly across the years, metaverses’ entry was round the corner. Still, the first real metaverses were released much later, albeit as play-to-earn games. The evolution of blockchain applications was instrumental in the formation of these metaverses. Particularly, non-fungible tokens (NFTs) played a crucial part in determining those platforms’ functionality. Today, metaverses have grown to become communication forums in some capacity. This blog talks about the process behind creating a metaverse wallet..",
  },
  {
    img: "https://www.blockchainappfactory.com/blog/wp-content/uploads/2022/06/Phygital-NFTs-%E2%80%93-Another-Avenue-for-Brands-to-Stay-Authentic-1200-x-760-copy.jpg",
    heading: "Use Phygital NFTs to Improve Your Brand’s Marketing Strategy",
    text: "Since non-fungible tokens (NFTs) went live for the first time, experts predicted that these could be beneficial for physical objects. And as time went on, NFTs spread their wings towards pretty much everywhere, and physical items were not an exception. Soon, real-world brands saw a big marketing opportunity in NFTs, and that is how these tokens ended up venturing as phygital NFTs. In this blog, we will talk about what phygital NFT marketplaces. Also how brands can utilize them for marketing themselves in the Web3.0 world.",
  },
  {
    img: "https://www.blockchainappfactory.com/blog/wp-content/uploads/2022/06/Utilize-NFT-Real-Estate-Development-to-Garner-a-Huge-Support-Among-the-General-Public-1200-x-760-copy.jpg",
    heading:
      "NFT Real Estate – Another Feather in the Cap for Web3 Technology!",
    text: "Since non-fungible tokens (NFTs) have become mainstays in today’s world, they have been touted as the next big thing that can change the world forever. With the advent of NFTs for physical assets, Web3 experts are going wild on the prospect of NFT real estate. To be precise, NFT real estate is one of the hot topics in the current world as more people have started understanding the significance of NFTs in such real-world applications. This blog vividly explains NFT real estate and how the sector can impact how people perceive Web3 technology.",
  },
];

export const portfolioData = [
  {
    img: CryptoImg,
    heading: "Crypto Invaderz",
  },
  {
    img: BuggedImg,
    heading: "Bugged Out",
  },
  {
    img: Game1,
    heading: "Web Game",
  },
  {
    img: Game2,
    heading: "Web Game",
  },
];

export const teamData = [
  // {
  //   img: SirShoaibImg,
  //   name: "Ch Shoaib Ishtiaq",
  //   designation: "CEO",
  // },
  // {
  //   img: SirAliImg,
  //   name: "Ali Raza",
  //   designation: "Head Of Development Team",
  // },
  // {
  //   img: UmerImg,
  //   name: "Umer Zaman",
  //   designation: "Senior Graphic Designer",
  // },
  // {
  //   img: AbdulImg,
  //   name: "Abdul Rehman",
  //   designation: "Graphic Designer",
  // },
  // {
  //   img: UsamaImg,
  //   name: "Usama Bashir",
  //   designation: "Full Stack Developer",
  // },
  // {
  //   img: UzairImg,
  //   name: "Uzair Munir",
  //   designation: "Frontend Developer",
  // },
  // {
  //   img: HassanImg,
  //   name: "Hassan Afzal",
  //   designation: "Backend Developer",
  // },
  // {
  //   img: ShoaibImg,
  //   name: "Muhammad Shoaib",
  //   designation: "Game Developer",
  // },
  // {
  //   img: SafeerImg,
  //   name: "Safeer Alam",
  //   designation: "Senior 3D Architecture",
  // },
];

export const servicesTextData = [
  {
    heading:
      "Assign your project to an experienced NFT game development company.",
    desc1: `Choose a skilled NFT game development company to handle your project.
  When you require block-chain based tokens as part of the priceless assets in your game, entrust your NFT game development needs to internationally renowned professionals. By selecting an expert firm to create your game on an NFT game development platform, you'll benefit from originality for upcoming commercial ventures. You gain the confidence and security of your consumers while NFT games create a new trend in the online gaming industry.`,
    desc2: `Allow your customers to actually own in-game things and move assets between games by utilising a blockchain-based platform. NFT games powered by blockchain allows player ownership and interoperability for a truly decentralised gameplay will help you stand out in the gaming market.`,
    desc3: `Unleash the magic of a blockchain-based platform to empower your users to truly own in-game items and transfer assets across multiple games. Stand out in the gaming market with blockchain-based NFT games that provide players with ownership and interoperability for truly decentralized gameplay. Lead the way with the latest NFT features to achieve greater reach as NFT sees better engagement.`,
    desc4: `Lead the way to generating opportunities that bring you more revenue with NFT games that enable secure token trading in the market. This ensures seamless payment processes that eliminate suspicious activity. Take advantage of the skill of NFT game developers who create and develop exciting games for an immersive experience.`,
  },
  {
    heading: "Create premium games with non-fungible token technology (NFT).",
    desc1: `Embrace a new trend in the crypto market with the excellent properties and exceptionally distinct characteristics of NFT that will shorten your game development process and give it more credibility. Store an unmodified persistent record on the blockchain with existing metadata in each NFT that conveniently describes ownership history and transaction records. Engage a large multi-age audience with a potentially huge gaming industry driving innovation around the world.`,
    desc2: `Embrace cutting-edge technology that delivers gaming industry excellence through a seamless and easy-to-use NFT trading experience. Use the phenomenal innovation of blockchain technology to generate your game assets like gear, special characters, and tickets. Tokenize your virtual gaming assets in NFT for exclusive sales that attract more attention.`,
    desc3: `
    Gain full control of the in-game items you win or purchase. Plus, you get full support with a unique article identity to securely store your assets. Monitor and keep a track record of your assets and easily trade them on blockchain markets with an NFT gaming platform.`,
  },
  {
    heading:
      "Create innovative games by leveraging the experience of NFT developers.",
    desc1: `Create engaging and intuitive NFT games with NFT game development company for traditional non-technical consumers who are motivated to participate and enthusiastically purchase game items for future profits. Challenge our experienced NFT game developers to boost your brand with NFTs that offer great trading opportunities for exclusive game tokens that attract more and more passionate players.`,
    desc2: `
    Leverage liquidity, immutability, verifiability, programmability, and other NFT factors to achieve the highest level of revenue. By partnering with developers to develop NFT games, you will attract huge traffic to your brand. Offer cross-platform purchases in the same chain with interconnectivity from NFT gaming platforms. Get more with in-game assets like maps, costumes, and other accessories with the Marketplace.`,
    desc3: `

    You can gain huge sums with blockchain-based programming for your game. You can develop almost any type of game as you normally would, but put everything on a secure platform that offers peace of mind for you and your users. The best part is that your tradable assets have unique ownership, not much different from owning a Picasso masterpiece in terms of uniqueness.`,
  },
  {
    heading: "No fear of theft apart from ownership uniqueness.",
    desc1: `
    Aside from the fact that your users own what they win or buy on your NFT gaming assets, there is almost zero chance of theft, which is a great feeling for them. It's something that keeps them coming back over and over. Imagine growing your following effortlessly when you have your game on the blockchain.`,
    desc2: `
    Gain the complete trust of your users when they approach you. From the very beginning, you will experience a new era for repeat customers. With their continued confidence built, your brand presence will continue to grow, and the rapid pace of that growth will benefit you immensely.`,
    desc3: `

    Develop, brainstorm and plan your NFT game with experienced developers who have been in the industry for over 10 years. Let an experienced NFT game development company guide you with a workshop to initiate processes that will lead you to new horizons with branding driven by a robust and secure gaming experience.`,
  },
];

export const discordDetailsData = [
  {
    logo: Server,
    heading: "Dedicated Server For Your Brand",
    text: "Firstly, we create a dedicated channel for your brand with different channels, topics, categories, and roles. We can either help you build one from the existing templates or from scratch. The choice is yours.",
  },
  {
    logo: Store,
    heading: "Set Up A StoreFront",
    text: "We will create a storefront to list your games or NFT collectibles. The daily reports and metrics of the sale will be analyzed, and your storefront will be customized accordingly by our team.",
  },
  {
    logo: Invite,
    heading: "Invite Joinees",
    text: "We start inviting new joinees to your Discord server, and to do it; we will organize attractive giveaway and incentive programs. ",
  },
  {
    logo: Content,
    heading: "Post Attractive Contents",
    text: "We will post different types of posters, designs, and copies that will keep your community engaging and will tempt them to be more interactive in the group. ",
  },
  {
    logo: Database,
    heading: "Data Collection",
    text: "The analytic data behind every post will be recorded and analyzed to come up with more interactive posts in the future. ",
  },
  {
    logo: Promote,
    heading: "Promote In Like-Minded Communities",
    text: "Our Discord marketing team will find like-minded NFT or gaming communities and promote your server there. ",
  },
  {
    logo: Query,
    heading: "Customer Queries",
    text: "A dedicated server to handle the queries of the consumers will be created. By doing this, you can reply to your customers instantly. ",
  },
  {
    logo: Events,
    heading: "Host Events",
    text: "Hosting events can attract a huge flock of users to your server. We will plan and organize events that are in line with your niche. ",
  },
];

export const artServicesTextData = [
  {
    heading: `
      What Is Crypto Art?
      `,
    desc1: `
    Crypto Art or NFT Art is a unique digital artwork that is tokenized on a blockchain network When an NFT Art or Crypto Art is created, a Non-Fungible Token (NFT) is also created, meaning the digital ownership will be stored on a robust blockchain network along with Art’s unique identification mechanism (Token ID). Though Art can be viewed and shared around the web by anyone, the value is held only by the current owner and artist.`,
    desc2: `When a collector of the NFT Art wants to move on from it, it can be placed for auction, and he/she can collect the payment in any form of cryptocurrencies. The artist will receive 10% from the further proceedings as royalty, the royalty percent may vary with respect to the NFT marketplaces.`,
    desc3: `Though the solemnity started off with the in-app purchases in the gaming industry, as a logical progression, it penetrated into the crypto-space because of the exclusivity of the guaranteed digital ownership of the artwork. Crypto Art Creative Marketing services use strategic communication techniques across multiple channels to attract more prospective NFT investors.`,
    desc4: `
    People are beady-eyed to purchase a Crypto Art because of the ascendance they could have over digital Art and cash in on the same. Crypto Art Consulting Services provide solid advice to entrepreneurs on the latest trends in the NFT market, information about changes in prices of crypto collectibles, and changing behaviour of investors in the cryptocurrency industry.`,
  },
  {
    heading: `
    Why Is NFT Art Becoming All The Rage?`,
    desc1: `A Crypto Art or NFT Art is unique. One NFT is not identical to another, which makes each art one of a kind.
    `,
    desc2: `The best way for artists to sell their art and for their fans to get ownership of their favourite creators’ arts.`,
    desc3: `
    This is a great investment for collectors who would want to foray into the crypto world and who want to take part in this ever-growing vertical.`,
    desc4: `The best revenue channel for artists to earn till the end of time, for their work whenever the art changes hands.`,
  },
  {
    heading: "How To Sell NFT Art?",
    desc1: `Stop mulling over watching thousands of tutorials about NFT and reach out to our service! With our whip-smart knowledge of Crypto Art and the fluctuant trends of the crypto world, we provide you with end-to-end knowledge on discrete strategies for promoting your art creations. Bid farewell to the time and energy wasted on knowing about the creative process of gaining visibility!.`,
    desc2: `
    
Our NFT Art Marketing Agency will free you from all the challenging promotion processes by offering all the comprehensive strategies you will need to lead. Be it presenting you with market research or ways to turn into a successful path; we provide wide-ranging tacks.
`,
  },
  {
    heading: "Crypto Art Creation?",
    desc1: `
    Crypto has been the grist of the gossip mill for more than a decade now. For having the best visibility, you need to implement digitization into your business with tech-savvy experiments like creating Crypto Arts.`,
    desc2: `
    
NFT Art is trending everywhere, capturing the attention of young adults. To gain a huge fan base, this could be an incredible opportunity to market.`,
    desc3: `

    Selling your Crypto Art can help you gain a huge revenue in a single go. More than any other sectors, as the cryptos are making circles in being the most lucrative assets, there is no question about its profits.`,
    desc4: `With the right Crypto Art campaign and best-suiting marketing strategies, you could power up your business. Also, employing other ways like link building and PR can help you amplify internet traffic and result in recognition.`,
  },
];

export const artServicesData = [
  {
    id: "1",
    text: "Will you sell my digital art to another customer?",
    desc: `We wouldn't. Our designers team make sure to create unique art for all the customers, and their attention never strays from providing the fantastic product.`,
  },
  {
    id: "2",
    text: "What are the famous blockchains for creating Crypto Art?",
    desc: `Non-fungible tokens are primarily created on the Ethereum blockchain on the standards ERC721 and ERC 1155 because of the support on many marketplaces.`,
  },
  {
    id: "3",
    text: `
  What are the steps involved in NFT Crypto Art Consultation?`,
    desc: `Our NFT crypto art consultation involves everything from brainstorming ideas to executing them for you. Apart from just this, we have a team for providing you with the best marketing services at your disposal.`,
  },
  {
    id: "4",
    text: `
  How to start selling art or how to invest in NFT?`,
    desc: `Just reach out to our team, and we will get back to you in the blink of an eye with all relevant options and the further steps to get started.`,
  },
  {
    id: "3",
    text: `
  Why choose your marketing service over others?`,
    desc: `With client’s satisfaction being our primary goal, we’ve been in business for over a decade. We know the pulse of the crypto market and wizards in providing solutions according to the trends. We love to see our clients grow!`,
  },
];

export const smartContractsData = [
  {
    heading: `What Is A Smart Contract? `,
    desc1: `NFT smart contract, in plain terms, means a digital trading agreement that carries a set of conditions. Such conditions have to be met in order for the NFT trading to take place successfully. Smart contracts run on the blockchain network and will automatically execute when any transaction is triggered. `,
    desc2: `Smart contracts are software programs that are coded to represent certain features or functionality. These smart contracts can be built on any blockchain like Solana, Ethereum, Polygon, Avalanche, TRON, etc., and will be based on a specific token standard respective to the chosen blockchain. Smart contracts are executed whenever functions like minting, trading, bidding, etc., happen on the platform.`,
  },
  {
    heading: `How Are NFTs & Smart Contracts Intertwined?`,
    desc1: `Smart contracts are embedded or coded into NFTs to govern the entire functionality. Let’s see through some examples of how these contracts work, thereby generating the required actions on any NFT marketplace. `,
    desc2: `Let’s take the example of an NFT gaming platform that features multiple in-game collectibles. Players will be enabled to own the collectibles if they accomplish some objectives or they can buy them using cryptocurrencies. Here, the program embedded into the smart contract will decide when the in-game collectibles have to be transferred to the players. So, this is how any smart contract will work, corresponding to the action specified in it.`,
  },
  {
    heading: `How Does Smart Contract Help In Defining Royalty Benefits?`,
    desc1: `The hype and adoption of NFTs are seeing rapid growth due to the underpinned value in the form of royalty. Previously, once NFTs are sold to a buyer, the creator will receive only the selling price. Only with advancements in smart contract development did royalty payments come into existence. Zora, OpenSea are the notable NFT trading platforms that allow NFT creators to decide the royalty percentage they’d like to get when their creations get sold on the platform. However, not all NFT trading platforms supported this royalty payment at that time.`,
    desc2: `Hence, the ERC-721 Royalty Standard was established to normalize royalty payments for original NFT creators. Let’s see how creators can fix royalties for their NFTs through smart contracts. Suppose the primary sale price of an NFT is 10 ETH. Now, if the creator chooses 5%, then he will receive 5 ETH as royalty rewards.`,
  },
];

export const smartContractFaqs = [
  {
    id: "1",
    text: `What Is An NFT Smart Contract?`,
    desc: `An NFT smart contract is a blockchain-based code that is developed to specify the functioning of NFTs and NFT marketplaces. The execution of these smart contracts will occur automatically when the corresponding function is triggered.`,
  },
  {
    id: "2",
    text: ` What Languages Are Smart Contracts Written In?`,
    desc: `The programming language for building smart contracts will vary depending on the blockchain using which smart contracts are built.`,
  },
  {
    id: "3",
    text: `Are Smart Contracts The Future?`,
    desc: `Yes! Since smart contracts are backed by blockchain tech and enable immutability and transparency, businesses and individuals can get mileage out of them.`,
  },
  {
    id: "4",
    text: `Do Smart Contracts Need Blockchain?`,
    desc: `A definite yes. Smart contracts run on blockchains (can be any blockchain tech), meaning they are programmed using blockchain-based programming languages.`,
  },
];

export const ourWorkListData = [
  {
    id: "software",
    heading: "Software Development",
    img: SoftSer,
    details: [
      {
        title: "Food Pos",
        img: Soft1,
        prevImg: Soft1,
      },
      {
        title: "Raiffeisen Bank",
        img: Soft2,
        prevImg: Soft2,
      },
      {
        title: "Sella SBS",
        img: Soft3,
        prevImg: Soft3,
      },
      {
        title: "Task Manager",
        img: Soft4,
        prevImg: Soft4,
      },
      {
        title: "Poetri All-in-one IDE",
        img: Soft5,
        prevImg: Soft5,
      },
      {
        title: "Alfa Bank Analytics",
        img: Soft6,
        prevImg: Soft6,
      },
      {
        title: "Blood Donations",
        img: Soft7Pre,
        prevImg: Soft7,
      },
      {
        title: "Tour System",
        img: Soft8,
        prevImg: Soft8,
      },
      {
        title: "Hospital Management",
        img: Soft9Pre,
        prevImg: Soft9,
      },
      {
        title: "System For Restaurant",
        img: Soft10Pre,
        prevImg: Soft10,
      },
    ],
  },
  {
    id: "websites",
    heading: "Web Development",
    img: WebSer,
    details: [
      {
        title: "HR company - Website design",
        prevImg: Web2P,
        img: Web2,
      },
      {
        title: "Health Initiatives Company",
        prevImg: Web3P,
        img: Web3,
      },
      {
        title: "Health Care Hospital ",
        prevImg: Web4P,
        img: Web4,
      },
      {
        title: "Fresh Vegan Meals",
        prevImg: Web5P,
        img: Web5,
      },
      {
        title: "Cloud Software Company ",
        prevImg: Web6P,
        img: Web6,
      },
      // {
      //   title: "Lorem Ipsum",

      //   img: Web6,
      //
      // },
      {
        title: "Creative Kits for Kids",
        prevImg: Web7P,
        img: Web7,
      },
      {
        title: "Coffee Online Store ",
        prevImg: Web8P,
        img: Web8,
      },
      {
        title: "Cloud Software Company ",
        prevImg: Web9P,
        img: Web9,
      },
      {
        title: "Intelligence Vehicle Technology  ",
        prevImg: Web1P,
        img: Web1,
      },
      {
        title: "Automation Software company",
        prevImg: Web10P,
        img: Web10,
      },
    ],
  },
  {
    id: "gaming",
    heading: "Game Development ",
    img: GamesSer,
    details: [
      {
        title: "Superhero Bike",
        img: Gamee,
        prevImg: Gamee,
      },
      {
        title: "Wild Deer Hunting",
        img: Gamee2,
        prevImg: Gamee2,
      },
      {
        title: "Mega Ramps",
        img: Gamee3,
        prevImg: Gamee3,
      },
      {
        title: "Snipper Shooters",
        img: Gamee4,
        prevImg: Gamee4,
      },
    ],
  },
  {
    id: "mobile",
    heading: "Mobile Apps",
    img: App,
    details: [
      {
        title: "Double Social App",
        img: Mob1P,
        prevImg: Mob1P,
      },
      {
        title: "Electronic Store App",
        img: Mob2Pre,
        prevImg: Mob2P,
      },
      {
        title: "Job Finder App",
        img: Mob3P,
        prevImg: Mob3P,
      },
      {
        title: "Melissa Delany  Delivery",
        img: Mob4P,
        prevImg: Mob4P,
      },
      {
        title: "Rill Food App",
        img: Mob5P,
        prevImg: Mob5P,
      },
      {
        title: "Social Media App",
        img: Mob6P,
        prevImg: Mob6P,
      },
    ],
  },

  {
    id: "nft",
    heading: "NFT Artwork",
    img: NftSer,

    details: [
      {
        id: "crypto-invaderz",
        title: "Crypto Invaderz",
        img: CryptoImg,
        prevImg: CryptoImg,
        link: "/:id",
      },
      {
        id: "bugged-our",
        title: "Bugged Out",
        img: BuggedImg,
        prevImg: BuggedImg,
        link: "/:id",
      },
      {
        id: "gunked-goblin-town",
        title: "Gunked Goblin Town",
        img: Game1,
        prevImg: Game1,
        link: "/:id",
      },
      {
        id: "urban-gobblerz",
        title: "Urban Gobblerz",
        img: Game2,
        prevImg: Game2,
        link: "/:id",
      },
    ],
  },

  {
    id: "graphics",
    heading: "GRAPHICS DESIGNING",
    img: BrandingSer,
    details: [
      {
        img: Fostec,
        title: "Fostec",
        prevImg: Fostec,
      },
      {
        img: Gotiket,

        title: "Gotiket",
        prevImg: Gotiket,
      },
      {
        img: Flaym,
        title: "Flaym",
        prevImg: Flaym,
      },
      {
        img: Hearte,
        title: "Hearte",
        prevImg: Hearte,
      },
    ],
  },

  {
    id: "contracts",
    heading: "Smart Contracts",
    img: ContractSer,
    details: [],
  },

  {
    id: "crypto",
    heading: "Crypto Wallet",
    img: CryptoSer,
    details: [],
  },
  {
    id: "metaverse",
    heading: "Metaverse",
    img: Metaser,
    details: [],
  },
];

export const ntfDetailsPageData = [
  {
    id: "crypto-invaderz",
    title: "Crypto Invaderz",
    imgages: [
      {
        img: C1,
      },
      {
        img: C2,
      },
      {
        img: C3,
      },
      {
        img: C4,
      },
      {
        img: C5,
      },
      {
        img: C6,
      },
      {
        img: C7,
      },
      {
        img: C8,
      },
      {
        img: C9,
      },
      {
        img: C10,
      },
      {
        img: C11,
      },
      {
        img: C12,
      },
      {
        img: C13,
      },

      {
        img: C14,
      },
      {
        img: C15,
      },
      {
        img: C16,
      },
      {
        img: C17,
      },
      {
        img: C18,
      },
      {
        img: C19,
      },
      {
        img: C20,
      },
    ],
  },
  {
    id: "bugged-our",
    title: "Bugged Out",
    imgages: [
      {
        img: B1,
      },
      {
        img: B2,
      },
      {
        img: B3,
      },
      {
        img: B4,
      },
      {
        img: B5,
      },
      {
        img: B6,
      },
      {
        img: B7,
      },
      {
        img: B8,
      },
      {
        img: B9,
      },
      {
        img: B10,
      },
      {
        img: B11,
      },
      {
        img: B12,
      },
      {
        img: B13,
      },

      {
        img: B14,
      },
      {
        img: B15,
      },
      {
        img: B16,
      },
      {
        img: B17,
      },
      {
        img: B18,
      },
      {
        img: B19,
      },
      {
        img: B20,
      },
    ],
  },
  {
    id: "gunked-goblin-town",
    title: "Gunked Goblin Town",
    imgages: [
      {
        img: G1,
      },
      {
        img: G2,
      },
      {
        img: G3,
      },
      {
        img: G4,
      },
      {
        img: G5,
      },
      {
        img: G6,
      },
      {
        img: G7,
      },
      {
        img: G8,
      },
      {
        img: G9,
      },
      {
        img: G10,
      },
      {
        img: G11,
      },
      {
        img: G12,
      },
      {
        img: G13,
      },

      {
        img: G14,
      },
      {
        img: G15,
      },
      {
        img: G16,
      },
      {
        img: G17,
      },
      {
        img: G18,
      },
      {
        img: G19,
      },
      {
        img: G20,
      },
    ],
  },
  {
    id: "urban-gobblerz",
    title: "Urban Gobblerz",
    imgages: [
      {
        img: U1,
      },
      {
        img: U2,
      },
      {
        img: U3,
      },
      {
        img: U4,
      },
      {
        img: U5,
      },
      {
        img: U6,
      },
      {
        img: G7,
      },
      {
        img: U8,
      },
      {
        img: U9,
      },
      {
        img: U10,
      },
      {
        img: U11,
      },
      {
        img: U12,
      },
      {
        img: U13,
      },

      {
        img: U14,
      },
      {
        img: U15,
      },
      {
        img: U16,
      },
      {
        img: U17,
      },
      {
        img: U18,
      },
      {
        img: U19,
      },
      {
        img: U20,
      },
    ],
  },
];
