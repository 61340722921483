import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomButton from "../shared/CustomButton";
import "../assets/css/GraphicsDetails.css";
import { useNavigate } from "react-router-dom";
import ContactForm from "./ContactForm";

const GraphicHero = () => {
  const navigate = useNavigate();
  return (
    <>
      {/*Discord Details Hero */}
      <div className="graphics-details-container">
        <Container className="home-container ">
          <Row className="home-row  services-hero-row">
            <Col
              xs="12"
              sm="12"
              md="8"
              lg="6"
              data-aos="fade-down-right"
              data-aos-anchor-placement="top-bottom"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <div className="home-left-column">
                <h6>DESIGN</h6>
                <h1>Designing Beyond Gravity</h1>
                <p>
                  At Aethon we love nothing more than transforming imagination
                  into reality. Our team specialise in branding, packaging,
                  illustration & advertising. So, whether you are looking to
                  convey ideas in beautiful simplicity or with mind-blowing
                  visuals, we'll create something that will make your audience
                  salivate.
                </p>
                <CustomButton
                  variant="success"
                  btnClick={() => navigate("/contact-us")}
                  style={{ width: "320px" }}
                >
                  Connect With Our Experts{" "}
                </CustomButton>
              </div>
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="6"
              data-aos="zoom-in-up"
              data-aos-anchor-placement="top-bottom"
            ></Col>
          </Row>
        </Container>
      </div>
      <div className="graphics-card-container" data-aos="fade-up">
        <Container>
          {/* <h3 className="text-center">Designing Process We Follow</h3> */}
          <Row className=" graphics-row">
            <Col sm="12" md="6" lg="4" data-aos="zoom-in-up">
              <div className="graphics-text-card">
                <h5>
                  From The Bottom To The Top <br />
                  Building Brands
                </h5>
                <p>
                  We build brands from the ground up. Injecting life,
                  personality and amazing stories. If you're the next big
                  start-up or an established brand needing an overhaul, our
                  employees are ready.
                </p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4" data-aos="zoom-in-up">
              <div className="graphics-text-card">
                <h5>
                  The Full Package <br />
                  Not Just a Logo Design
                </h5>
                <p>
                  We're a multi-talented school that delivers a complete brand
                  makeover. Packaging, brand guidelines, stationery, vehicles,
                  logos, adverts... if it needs to look good, we've got you
                  covered.
                </p>
              </div>
            </Col>
            <Col sm="12" md="6" lg="4" data-aos="zoom-in-up">
              <div className="graphics-text-card">
                <h5>
                  You Can't Beat A Bit Of Bully! <br />
                  Always on Point
                </h5>
                <p>
                  Our employees constantly evolve to keep our designs fresh and
                  tasty. We strive to push boundaries that capture the attention
                  of our clients' audiences. We create work that people want to
                  see.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "#0d1321" }}>
        <ContactForm />
      </div>
    </>
  );
};

export default GraphicHero;
