import React from "react";
import { Button } from "react-bootstrap";
import "../assets/css/CustomButton.css";

const CustomButton = ({ btnClick, variant, children, ...rest }) => {
  if (btnClick) {
    return (
      <Button
        variant={variant}
        onClick={() => btnClick()}
        className="custom-btn"
        {...rest}
      >
        {children}
      </Button>
    );
  } else {
    return (
      <Button variant={variant} className={`custom-btn `} {...rest}>
        {children}
      </Button>
    );
  }
};

export default CustomButton;
