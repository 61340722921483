import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import EnrollTopImg from '../assets/images/enroll-top.png';

const EnrollTop = () => {
    return (
        <Container className="home-container my-5">
            <Row className="home-row">
                <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="6"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-easing="linear"
                    data-aos-duration="500"
                >
                    <div className="home-left-column">
                        <h6>Learn with Aethon</h6>
                        <h1>About Aethon Courses</h1>
                        <p>
                            Offering Courses the excitement they want in their games. Leverage NFT Game Development for credible and secure game assets.
                        </p>
                    </div>
                </Col>
                <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="6"
                    data-aos="zoom-in-up"
                    data-aos-anchor-placement="top-bottom"
                >
                    <Image
                        src={EnrollTopImg}
                        lazyload="true"
                        className="home-img"
                        rounded="true"
                        alt="home-img"
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default EnrollTop;
